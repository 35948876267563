import { initializeApp } from 'firebase/app';
import {
  deleteToken as deleteFirebaseToken,
  getMessaging,
  getToken,
} from 'firebase/messaging';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useAuthContext } from '../context/authContext';
import {
  DeviceToken,
  useCreateDeviceMutation,
  useDeleteDeviceMutation,
} from '../graphql/generated';

import type {
  NotificationContext,
  NotificationProviderProps,
} from './useNotification';

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY || '',
  authDomain: process.env.FIREBASE_AUTH_DOMAIN || '',
  projectId: process.env.FIREBASE_PROJECT_ID || '',
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID || '',
  appId: process.env.FIREBASE_APP_ID || '',
};

// Store the config as params to pass to the service worker
const firebaseConfigAsParams = new URLSearchParams(firebaseConfig);

const defaultNotificaitonContext: NotificationContext = {
  deleteToken: () => Promise.resolve(void 0),
  clearNotificationData: () => void 0,
  registerForPushNotifications: () => void 0,
};

const notificationContext = createContext<NotificationContext>(
  defaultNotificaitonContext
);

export const NotificationProvider = ({
  children,
}: NotificationProviderProps) => {
  const { Provider } = notificationContext;

  const app = initializeApp(firebaseConfig);
  const [createDevice] = useCreateDeviceMutation();
  const { token: authToken } = useAuthContext();
  const [notificationData, setNotificationData] =
    useState<NotificationContext['notificationData']>();

  const clearNotificationData = () => {
    setNotificationData(undefined);
  };

  // Initialize Firebase Cloud Messaging and get a reference to the service
  const messaging = getMessaging(app);
  const registerForPushNotificationsAsync = async () => {
    // We have to register our own service worker to provide the config values
    const serviceWorkerRegistration = await navigator.serviceWorker.register(
      `/firebase-messaging-sw.js?${firebaseConfigAsParams}`
    );

    if (
      window &&
      'Notification' in window &&
      Notification.permission !== 'denied'
    ) {
      const permission = await Notification.requestPermission();
      if (permission !== 'denied') {
        const token = await getToken(messaging, {
          vapidKey: process.env.FIREBASE_VAPID_KEY,
          serviceWorkerRegistration,
        });
        return token;
      }
    }
  };

  useEffect(() => {
    registerForPushNotifications();
  }, []);

  const registerForPushNotifications = () => {
    registerForPushNotificationsAsync().then((pushToken) => {
      if (authToken && pushToken) {
        createDevice({
          variables: {
            attributes: {
              token: pushToken,
              tokenType: DeviceToken.Firebase,
            },
          },
        });
      }
    });
  };

  // Handle message events from the service worker
  navigator &&
    'serviceWorker' in navigator &&
    navigator.serviceWorker.addEventListener(
      'message',
      (event: MessageEvent<{ type: 'LOAD_CHAT'; payload: string }>) => {
        if ('type' in event.data && event.data.type === 'LOAD_CHAT') {
          setNotificationData({ chatId: event.data.payload });
        }
      }
    );

  const [deleteDevice] = useDeleteDeviceMutation();
  const deleteToken = async (): Promise<void> => {
    if (
      window &&
      'Notification' in window &&
      Notification.permission !== 'granted'
    ) {
      return Promise.resolve();
    }

    return getToken(messaging)
      .then((token) => {
        if (!token) {
          return Promise.resolve(void 0);
        }
        return token;
      })
      .then(async (token) => {
        const success = await deleteFirebaseToken(messaging);

        if (success) {
          return token;
        }

        return Promise.reject('Failed to delete Firebase token');
      })
      .then((token) => {
        return deleteDevice({
          variables: { expoToken: token as string },
        });
      })
      .catch(() => Promise.reject())
      .then(() => Promise.resolve());
  };

  const value = useMemo(
    () => ({
      deleteToken,
      notificationData,
      clearNotificationData,
      registerForPushNotifications,
    }),
    [notificationData]
  );

  return <Provider value={value}>{children}</Provider>;
};

export const useNotification = () => {
  const context = useContext(notificationContext);

  if (!context) {
    throw new Error('useNotification must be used within a Provider');
  }

  return context;
};

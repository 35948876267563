import { useNavigation } from '@react-navigation/native';
import debounce from 'lodash.debounce';
import React, { useEffect, useState, useCallback, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  KeyboardAvoidingView,
  Platform,
  SectionList,
} from 'react-native';
import { FlatList } from 'react-native-gesture-handler';

import { MyTagModalHeader } from '@components/Chat/MyTagModalHeader.web';
import { ProjectTagList } from '@components/Chat/ProjectTagList';
import TagsCollection, {
  TagsCollectionType,
} from '@components/Chat/TagsCollection';
import { TaskTagList } from '@components/Chat/TaskTagList';
import EmptyStateNoProjectTag from '@components/EmptyState/EmptyStateNoProjectTag';
import { Box, ShadowBox, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/RestyleIcon';
import {
  useUpdateMessageTagsMutation,
  ProjectSortOption,
  Project,
  useSearchTagsLazyQuery,
  SearchableResult,
} from '@graphql/generated';
import { useApolloClient } from '@hooks/useApolloClient';
import useChatInput from '@hooks/useChatInput';
import { useGetMessageFromCache } from '@hooks/useGetMessageFromCache';
import { useListProjectsTasksFromQuery } from '@hooks/useListProjectsTasksFromQuery';
import { useListProjectsTasksRecentFromQuery } from '@hooks/useListProjectsTasksRecentFromQuery';
import useMe from '@hooks/useMe';
import { Search, SearchResultType } from '@root/types';
import { ProjectTagType } from '@src/types/project';
import { TaskTagType } from '@src/types/task';
import theme from '@themes/theme';
import {
  generateTagCollections,
  tagsCollectionToProjectIds,
  tagsCollectionToTaskIds,
} from '@utils/tagCollectionService';
import { isProjectSearchResult, isTaskSearchResult } from '@utils/typeGuards';

const DEBOUNCE = 500;
const PER_PAGE_COUNT = 10;

interface EditMyTagsModal {
  onDonePress?: () => void;
  onCancelPress: () => void;
}

export const EditMyTagsModal: React.FC<EditMyTagsModal> = ({
  onDonePress,
  onCancelPress,
}) => {
  const { selectedMessage } = useChatInput();
  const chatId = selectedMessage?.chatId;

  const { client } = useApolloClient();

  const cacheIdentifier = client?.cache.identify(selectedMessage);

  const message = useGetMessageFromCache(cacheIdentifier);

  const { t } = useTranslation('models');
  const { me } = useMe();
  const [search, setSearch] = useState<string>('');

  const [tagsCollection, setTagsCollection] = useState<TagsCollectionType[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation();

  const flatListRef = createRef<FlatList>();
  const [scrollEnabled, setScrollEnabled] = useState<boolean>(false);
  const { attachments } = message || {};
  const [myTagsCollection, setMyTagsCollection] = useState<
    TagsCollectionType[]
  >(
    generateTagCollections(attachments || [])
      .flat()
      .filter((i) => i.author.id === me?.id)
  );

  const { id: myId } = me || { id: '' };

  const errorMessageAlert = () => {
    Alert.alert(
      t('chat.editTags.error.title'),
      t('chat.editTags.error.subtitle'),
      [
        { text: t('chat.editTags.error.cancel') },
        {
          text: t('chat.editTags.error.ok'),
          onPress: saveEdits,
        },
      ]
    );
  };

  const [updateMessageTags, { loading: updateMessageTagsLoading }] =
    useUpdateMessageTagsMutation({
      onCompleted: () =>
        Platform.OS === 'web' && onDonePress
          ? onDonePress()
          : navigation.goBack(),
      onError: (_e) => {
        errorMessageAlert();
      },
    });

  //All project and tasks api

  const {
    projectsTasks: allProjectsTasks,
    refetch: refetchProjects,
    refreshing: refreshingProjects,
    setRefreshing: setRefreshingProjects,
    fetchMoreFromCursor: fetchMoreProjectsFromCursor,
    loading: allProjectsTasksLoading,
    pageInfo,
  } = useListProjectsTasksFromQuery({
    first: PER_PAGE_COUNT,
    sortBy: ProjectSortOption.NameAsc,
    chatId: chatId,
  });

  //recent project and tasks

  const {
    recentProjectsTasks: recentProjectTasks = [],
    loading: recentProjectsTasksLoading,
  } = useListProjectsTasksRecentFromQuery({
    chatId: chatId,
  });

  const projectsTasksData = [
    { title: 'Recent Project & Tasks', data: recentProjectTasks },
    { title: 'All Projects & Tasks', data: allProjectsTasks },
  ];

  const sectionHeader = ({ section: { title, data } }) => {
    return (
      data &&
      data.length > 0 && (
        <Box padding='xs' backgroundColor='white'>
          <Text color='grey04' variant='bodySecondary' marginLeft='xs'>
            {title}
          </Text>
        </Box>
      )
    );
  };

  const [globalSearch, { data, loading: loadingSearch }] =
    useSearchTagsLazyQuery();
  const searchData = (data?.search as Search<SearchableResult>[]) || [];
  const initialResults = {
    messages: [],
    tasks: [],
    documents: [],
    projects: [],
    contacts: [],
  };

  const results =
    searchData.reduce<SearchResultType>((previous, result) => {
      if (isProjectSearchResult(result))
        return { ...previous, projects: [...previous.projects, result] };
      if (isTaskSearchResult(result))
        return { ...previous, tasks: [...previous.tasks, result] };

      return previous;
    }, initialResults) || initialResults;

  const searchResults = results.projects
    ? results.projects.map((r) => r.record)
    : [];

  const globalSearchCall = () =>
    globalSearch({
      variables: {
        term: search,
        size: 100,
        includeMessages: false,
        includeDocuments: false,
        includeProjects: true,
        includeTasks: false,
        includeChats: false,
        includeTasksTags: true,
        includeCompletedTask: true,
      },
      onCompleted: () => setLoading(false),
      onError: () => setLoading(false),
    });

  const debouncedGlobalSearch = useCallback(
    debounce(globalSearchCall, DEBOUNCE),
    [search]
  );

  useEffect(() => {
    if (search.length) {
      setLoading(true);
      debouncedGlobalSearch();
    }
    return () => {
      debouncedGlobalSearch.cancel();
      setLoading(false);
    };
  }, [search]);

  const removeTagsCollectionTask = (
    tagCollectionIndex: number,
    taskIndex: number
  ) => {
    const newTagsCollection = myTagsCollection.map((tagCollection, index) => {
      if (index === tagCollectionIndex) {
        return {
          ...tagCollection,
          tasks: tagCollection.tasks.filter(
            (_t, tIndex) => tIndex !== taskIndex
          ),
        };
      }
      return tagCollection;
    });

    setMyTagsCollection(newTagsCollection);
  };

  const removeTagsCollection = (tagCollectionIndex: number) => {
    const newTagsCollection = myTagsCollection.filter(
      (_, index) => index !== tagCollectionIndex
    );

    setMyTagsCollection(newTagsCollection);
  };

  const updateTagsCollectionProject = (project: ProjectTagType) => {
    if (!me) return;
    const existingProjects = myTagsCollection.filter(
      (i) => i.project.id === project.id
    );
    if (existingProjects.length === 0) {
      setMyTagsCollection([
        ...myTagsCollection,
        {
          tasks: [],
          project: { ...project },
          author: me,
        },
      ]);
    }
  };

  const updateTagsCollectionTasks = (task: TaskTagType) => {
    if (!me) return;

    const collectionToUpdate = myTagsCollection.findIndex(
      (t) => t.project.id === task.project.id
    );
    // update an existing tag collection item
    if (collectionToUpdate !== -1) {
      const newTagsCollection = myTagsCollection.map((tagCollection, index) => {
        if (index === collectionToUpdate) {
          const existingTasks = tagCollection.tasks.filter(
            (i) => i.id === task.id
          );
          return {
            ...tagCollection,
            tasks:
              existingTasks.length === 0
                ? [...tagCollection.tasks, task]
                : [...tagCollection.tasks],
          };
        }
        setMyTagsCollection(newTagsCollection);
        return tagCollection;
      });
      setMyTagsCollection(newTagsCollection);
    } else {
      // new tag collection item
      setMyTagsCollection([
        ...myTagsCollection,
        {
          tasks: [task],
          project: task.project,
          author: me,
        },
      ]);
    }
  };

  useEffect(() => {
    if (!message || !me) return;
    //Added this because after search selected tag are reset
    if (!search) {
      setTagsCollection(
        generateTagCollections(attachments || [])
          .flat()
          .filter((i) => i.author.id !== me.id)
      );
    }
  }, [message, me]);
  const saveEdits = () => {
    const { id: chatId } = message || {};

    if (message && chatId && myId) {
      const taskIds = tagsCollectionToTaskIds([
        ...tagsCollection,
        ...myTagsCollection,
      ]);

      const projectIds = tagsCollectionToProjectIds([
        ...tagsCollection,
        ...myTagsCollection,
      ]);

      updateMessageTags({
        variables: {
          id: message?.id,
          attributes: {
            taskIds,
            projectIds,
          },
        },
      });
    }
  };

  const renderSearch = (item: Project) => {
    return (
      <Box>
        <ProjectTagList
          key={item.id}
          refreshing={refreshingProjects}
          loading={false}
          onRefresh={() => {
            setRefreshingProjects(true);
            refetchProjects();
          }}
          filterVal={search}
          projectTags={[item]}
          onSelect={(newProject: ProjectTagType) =>
            updateTagsCollectionProject(newProject)
          }
        />

        {item?.tasksTagList?.map((taskObj) => {
          const isSelectedTask =
            tagsCollection?.find((tag) =>
              tag.tasks.find((task) => task.id === taskObj.id)
            ) ||
            myTagsCollection?.find((tag) =>
              tag.tasks.find((task) => task.id === taskObj.id)
            );
          if (isSelectedTask) return;
          return (
            <TaskTagList
              key={taskObj.id}
              filterVal={search}
              taskTags={[taskObj]}
              showMember
              onSelect={(newTask: TaskTagType) =>
                updateTagsCollectionTasks(newTask)
              }
              loading={false}
            />
          );
        })}
      </Box>
    );
  };

  const showEmptyState = projectsTasksData.every((i) => i.data?.length === 0);

  const renderHeader = () => {
    return (
      <Box paddingHorizontal='m'>
        <FlatList
          keyboardShouldPersistTaps='always'
          ref={flatListRef}
          onContentSizeChange={(_w, h) => {
            h > 100 ? setScrollEnabled(true) : setScrollEnabled(false);
          }}
          scrollEnabled={scrollEnabled}
          style={{ maxHeight: 100 }}
          data={myTagsCollection}
          renderItem={({ item, index }) => {
            return (
              <TagsCollection
                key={index}
                tagsCollection={item}
                onDelete={(i: number) => removeTagsCollectionTask(index, i)}
                onDeleteProject={() => removeTagsCollection(index)}
              />
            );
          }}
          ListHeaderComponent={() => (
            <Box marginTop='m'>
              {tagsCollection.map((item, index) => {
                return (
                  <Box key={index}>
                    <TagsCollection tagsCollection={item} />
                  </Box>
                );
              })}
            </Box>
          )}
          ListFooterComponent={() => <Box marginTop='s' />}
          ItemSeparatorComponent={() => <Box marginTop='xs' />}
        />
      </Box>
    );
  };

  const renderFooterUI = (isLoadingMore: boolean) => {
    return (
      <Box marginBottom='listFooter'>
        {isLoadingMore && <ActivityIndicatorLoading />}
      </Box>
    );
  };
  const renderItem = ({ item, section }: { item: any; section: any }) => {
    if (section.title === 'Recent Project & Tasks') {
      const isSelectedTask =
        tagsCollection?.find((tag) =>
          tag.tasks.find((task) => task.id === item.id)
        ) ||
        myTagsCollection?.find((tag) =>
          tag.tasks.find((task) => task.id === item.id)
        );
      return (
        <Box>
          {!item.projectId && (
            <ProjectTagList
              key={item.id}
              loading={false}
              filterVal={search}
              projectTags={[item]}
              onSelect={(newProject: ProjectTagType) =>
                updateTagsCollectionProject(newProject)
              }
            />
          )}

          {item.projectId && !isSelectedTask && (
            <TaskTagList
              key={item.id}
              filterVal={search}
              taskTags={[item]}
              showMember
              onSelect={(newTask: TaskTagType) =>
                updateTagsCollectionTasks(newTask)
              }
              loading={false}
            />
          )}
        </Box>
      );
    } else {
      return (
        <Box>
          <ProjectTagList
            key={item.id}
            refreshing={refreshingProjects}
            loading={false}
            onRefresh={() => {
              setRefreshingProjects(true);
              refetchProjects();
            }}
            filterVal={search}
            projectTags={[item]}
            onSelect={(newProject: ProjectTagType) =>
              updateTagsCollectionProject(newProject)
            }
          />

          {item?.tasks?.map((taskObj) => {
            const isSelectedTask =
              tagsCollection?.find((tag) =>
                tag.tasks.find((task) => task.id === taskObj.id)
              ) ||
              myTagsCollection?.find((tag) =>
                tag.tasks.find((task) => task.id === taskObj.id)
              );
            if (isSelectedTask) return;
            return (
              <TaskTagList
                key={taskObj.id}
                filterVal={search}
                taskTags={[taskObj]}
                showMember
                onSelect={(newTask: TaskTagType) =>
                  updateTagsCollectionTasks(newTask)
                }
                loading={false}
              />
            );
          })}
        </Box>
      );
    }
  };

  return (
    <ShadowBox
      accessibilityLabel='Tag Modal'
      backgroundColor='white'
      flex={1}
      borderRadius='m'
      borderWidth={1}
      borderColor='grey01'>
      <MyTagModalHeader
        filterVal={search}
        isTagSearch={true}
        onChangeFilterVal={(newFilterVal: string) => setSearch(newFilterVal)}
        onCancelPress={onCancelPress}
      />
      <KeyboardAvoidingView style={{ flex: 1 }}>
        {renderHeader()}

        {showEmptyState &&
          !allProjectsTasksLoading &&
          !recentProjectsTasksLoading &&
          !search && (
            <Box
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: theme.spacing.listFooter,
              }}>
              <EmptyStateNoProjectTag />
            </Box>
          )}

        {search.length > 0 && loadingSearch && <ActivityIndicatorLoading />}

        {!showEmptyState && (
          <>
            {search &&
              !loading &&
              !loadingSearch &&
              searchResults.length === 0 && (
                <Box
                  flex={1}
                  alignItems='center'
                  justifyContent='center'
                  alignContent='center'>
                  <Box
                    flex={1}
                    justifyContent='center'
                    alignItems='center'
                    alignContent='center'
                    flexDirection='column'
                    alignSelf='center'
                    paddingBottom='xxl'
                    paddingTop='xxl'>
                    <Icon name='NoSearchTask' width={141} height={105}></Icon>
                    <Text
                      variant='heading1'
                      textAlign='center'
                      paddingBottom='xs'
                      color='onSurfaceSecondary'>
                      {t('shared:noResults')}
                    </Text>
                    <Text
                      variant='body'
                      textAlign='center'
                      color='onSurfaceSecondary'>
                      {t('models:tasks.filter.activeTasksEmpty')}
                    </Text>
                  </Box>
                </Box>
              )}
            {projectsTasksData.length !== 0 && search.length === 0 && (
              <SectionList
                showsVerticalScrollIndicator={true}
                sections={projectsTasksData}
                keyboardShouldPersistTaps='always'
                renderItem={renderItem}
                renderSectionHeader={sectionHeader}
                initialNumToRender={10}
                ListFooterComponent={() =>
                  renderFooterUI(pageInfo?.hasNextPage || false)
                }
                onEndReached={() => {
                  fetchMoreProjectsFromCursor();
                }}
              />
            )}

            {search.length > 0 && (
              <FlatList
                data={searchResults}
                keyboardShouldPersistTaps='always'
                renderItem={({ item }) => {
                  return <>{renderSearch(item)}</>;
                }}
              />
            )}
          </>
        )}

        <Button
          backgroundColor='textPrimary'
          prefix={<Icon variant='l' name='Check' color='white' />}
          onPress={saveEdits}
          borderRadius='xl'
          variant='primary'
          prefixMarginRight='s'
          float='bottom-right'
          disabled={updateMessageTagsLoading}
          accessibilityLabel={t('chat.editTags.done')}>
          {t('chat.editTags.done')}
        </Button>
      </KeyboardAvoidingView>
    </ShadowBox>
  );
};

import { ResponsiveValue } from '@shopify/restyle';
import * as Clipboard from 'expo-clipboard';
import { openURL } from 'expo-linking';
import linkifyIt from 'linkify-it';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, TextProps } from 'react-native';
import {
  Config,
  isTriggerConfig,
  parseValue,
  Part,
} from 'react-native-controlled-mentions';
import Highlighter from 'react-native-highlight-words';
import Hyperlink from 'react-native-hyperlink';
import tlds from 'tlds';

import { Alert } from '@components/Alert';
import CachedImage from '@components/CachedImage/CachedImage';
import { EVERYONE_ID } from '@components/Chat/MentionSuggestions';
import { Text } from '@components/Restyle';
import Box from '@components/Restyle/Box';
import Icon from '@components/shared/Icon/Icon';
import { ChatDrawerScreen } from '@components/Web/Drawer/WebDrawerContext';
import {
  Attachment,
  Document,
  Maybe,
  useCreateChatMutation,
} from '@graphql/generated';
import useActiveChat from '@hooks/useActiveChat';
import { useAlert } from '@hooks/useAlert';
import { useAppNavigation } from '@navigation/useAppNavigation';
import theme, { Theme } from '@themes/theme';
import { generateTagCollections } from '@utils/tagCollectionService';

import TagsCollection from './TagsCollection';

const linkify = linkifyIt();
const linkifyConfig = linkify.tlds(tlds);

type ReplyMessagePartsType = {
  value: string;
  configs: Config[];
  styleLinks?: boolean;
  filterVal?: string;
  textColor?: ResponsiveValue<keyof Theme['colors'], Theme['breakpoints']>;

  // if rendering mention from a non-chat detail screen
  navigateToChatOnMentionPress?: boolean;
  attachments?: Maybe<Array<Attachment>>;
} & TextProps;

const ReplyMessageParts: React.FC<ReplyMessagePartsType> = ({
  value,
  configs,
  styleLinks,
  filterVal = '',
  textColor = 'textSecondary',
  attachments,
  navigateToChatOnMentionPress,
  ...rest
}) => {
  const { parts } = parseValue(value, configs);
  const { setActiveChat } = useActiveChat();
  const navigate = useAppNavigation();
  const { showAlert } = useAlert();
  const { t } = useTranslation('models');

  const [createChat] = useCreateChatMutation({
    onCompleted: (data) => {
      const { createChat: createChatData } = data;
      setActiveChat(createChatData);

      if (navigateToChatOnMentionPress) {
        navigate.navigateToChatDrawer({
          screen: ChatDrawerScreen.details,
          chatId: createChatData.id,
        });
      }
    },
  });

  const highlightText = (body: string) => {
    if (!filterVal) {
      return body;
    }
    return (
      <Highlighter
        autoEscape
        highlightStyle={{
          backgroundColor: theme.colors.yellowBright,
        }}
        searchWords={filterVal.split(' ')}
        textToHighlight={body}
      />
    );
  };

  const renderPart = (part: Part, index: number) => {
    // Mention type part
    if (part.config && isTriggerConfig(part.config)) {
      const { id: userId } = part.data || { userId: '' };
      const disablePress = !userId || userId === EVERYONE_ID;

      const onPress = () => {
        if (userId) {
          // with parts, open new chat with id
          // find name from users
          createChat({
            variables: {
              attributes: {
                userIds: [userId],
              },
            },
          });
        }
      };

      return (
        <Text
          key={index}
          style={!filterVal && part.config.textStyle}
          variant='labelSmall'
          color={textColor}
          onPress={disablePress ? undefined : onPress}>
          {highlightText(part.text)}
        </Text>
      );
    }

    return (
      <Text
        variant='bodySecondary'
        color={textColor}
        key={index}
        style={{
          borderWidth: 0,
          borderColor: 'transparent',
        }}>
        {highlightText(part.text)}
      </Text>
    );
  };
  const tagsByAuthorCollections = generateTagCollections(attachments || []);
  const isDocumentType = (item: Attachment): item is Document =>
    item.__typename === 'Document';

  const documentAttachments: Document[] =
    attachments === undefined
      ? []
      : (attachments?.filter((item) => {
          return isDocumentType(item);
        }) as Document[]);

  const audioAttachments = documentAttachments.filter((item) => item.isAudio);
  const imageAttachments = documentAttachments.filter((item) => item.isImage);
  const fileAttachments = documentAttachments.filter(
    (item) => !item.isImage && !item.isAudio
  );

  const hasFile =
    audioAttachments.length > 0 ||
    imageAttachments.length > 0 ||
    fileAttachments.length > 0;
  const hasProjectTag = !hasFile && tagsByAuthorCollections.length > 0;
  const hasText = value.length > 0;

  const renderTagsCollections = () => {
    if (tagsByAuthorCollections.length > 0) {
      const tagsCollection = tagsByAuthorCollections.flat();

      return (
        <Box flexDirection='column' flex={1}>
          {tagsCollection.length > 0 &&
            tagsCollection.map((tags, collectionIndex) => (
              <TagsCollection
                key={collectionIndex}
                showAuthor={false}
                borderless
                tagsCollection={{
                  tasks: tags.tasks || [],
                  project: tags.project || {},
                  author: tags.author || {},
                }}
                filterVal=''
              />
            ))}
        </Box>
      );
    }
    return null; // or any other fallback if there are no tags
  };

  const renderParts = (
    <Box flex={1} flexDirection='row' alignItems='center' marginTop='xs'>
      {/* {renderTagsCollections()} */}
      {audioAttachments.length > 0 && (
        <Box flexDirection='row' alignItems='center'>
          <Icon name='Mic' color='grey05' variant='s' />
          {!hasText && (
            <Text
              variant='bodySecondary'
              color='textSecondary'
              numberOfLines={1}
              marginLeft='xs'>
              {t('chat.labels.audio_1')}
            </Text>
          )}
        </Box>
      )}
      {imageAttachments.length > 0 && (
        <Box flexDirection='row' alignItems='center'>
          <CachedImage
            image={imageAttachments[0].file}
            width={34}
            height={34}
            placeholderContentFlex={0}
            isRemoveable={false}
            borderRadius={theme.spacing.xxs}
          />
          {!hasText && (
            <Text
              variant='bodySecondary'
              color='textSecondary'
              numberOfLines={1}
              marginLeft='xs'>
              {imageAttachments.length === 1
                ? t('chat.labels.photo_one')
                : imageAttachments.length.toString() +
                  ' ' +
                  t('chat.labels.photo_other')}
            </Text>
          )}
        </Box>
      )}
      {fileAttachments.length > 0 && (
        <Box flexDirection='row' alignItems='center'>
          <Icon
            key={fileAttachments[0].id}
            fileType={fileAttachments[0].contentType || 'unknown'}
            color='black'
            variant='m'
          />
          {!hasText && (
            <Text
              variant='bodySecondary'
              color='textSecondary'
              numberOfLines={1}
              marginLeft='xs'>
              {fileAttachments.length === 1
                ? fileAttachments[0].name
                : fileAttachments.length.toString() +
                  ' ' +
                  t('chat.labels.file_other')}
            </Text>
          )}
        </Box>
      )}
      <Box flexDirection='column' flex={1}>
        {renderTagsCollections()}
        {hasText && (
          <Box flex={1}>
            <Text
              marginLeft={hasFile || hasProjectTag ? 'xs' : 'none'}
              {...rest}>
              {parts.map(renderPart)}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );

  if (styleLinks) {
    return (
      <Hyperlink
        linkify={linkifyConfig}
        onLongPress={(url) => {
          Clipboard.setStringAsync(url).then(() => {
            const copiedConfirm = `${url} copied to clipboard`;
            showAlert(copiedConfirm);
          });
        }}
        onPress={(url) => {
          if (Platform.OS == 'web') {
            window.open(url, '_blank');
          } else {
            openURL(url).catch(() => Alert.alert(`invalid URL: ${url}`));
          }
        }}
        linkStyle={{
          color: theme.colors.blue,
        }}>
        {renderParts}
      </Hyperlink>
    );
  } else {
    return <>{renderParts}</>;
  }
};

export default ReplyMessageParts;

/* eslint-disable no-nested-ternary */
import { Field, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, TouchableOpacity } from 'react-native';
import { InferType } from 'yup';

import RadioButton from '@components/RadioButton/RadioButton';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/RestyleIcon';
import TextField from '@components/shared/TextField/TextField';
import { CheckBoxButton } from '@components/Web/CheckBoxButton';
import { addChecklistSchema } from '@schemas/addChecklistSchema';

type addChecklistProps = {
  submit: (value: FormValues) => void;
  setShowAddChecklist: (isShowAddChecklist: boolean) => void;
  processing: boolean;
};

export type FormValues = InferType<typeof addChecklistSchema>;

export const AddChecklist: React.FC<addChecklistProps> = ({
  submit,
  setShowAddChecklist,
  processing,
}) => {
  const { t } = useTranslation('models');
  const [showRequired, setShowRequired] = useState(false);
  const initialValue: FormValues = {
    id: null,
    name: '',
    completed: false,
    sort: 0,
  };
  return (
    <Formik
      initialValues={initialValue}
      validationSchema={addChecklistSchema}
      onSubmit={(values, { resetForm }) => {
        if (!values.name) {
          setShowRequired(true);
          return;
        }
        submit(values);
        Platform.OS === 'web' && resetForm();
      }}>
      {({ isValid, handleSubmit, setFieldValue }) => (
        <Box flexDirection='row' alignItems='center' paddingLeft='m'>
          <Box marginRight='xs'>
            {Platform.OS !== 'web' && <Icon name='Move1' variant='l' />}
          </Box>

          <Box marginRight='xs'>
            {Platform.OS === 'web' ? (
              <CheckBoxButton checked={false}></CheckBoxButton>
            ) : (
              <RadioButton onPress={() => {}} isSelected={false} />
            )}
          </Box>
          <Box flex={1} ml='xs' mr='xs' pt='xxs' alignItems='center'>
            <Field
              component={TextField}
              name='name'
              isRequired={false}
              autoFocus
              borderless={Platform.OS !== 'web'}
              marginBottom='xxs'
              marginTop={isValid ? undefined : 'm'}
              placeholder={t('tasks.checklist.item')}
              accessibilityLabel={t('tasks.checklist.item')}
              textInputProps={{
                fontSize: 16,
                fontHeight: 21,
                maxLength: 100,
                onSubmitEditing: () => {
                  isValid && handleSubmit();
                },
              }}
            />
            {showRequired && (
              <Text variant='error'>{t('shared:required')}</Text>
            )}
          </Box>
          {!processing && (
            <Box mr='m'>
              <TouchableOpacity
                onPress={() => {
                  setFieldValue('name', '');
                  setShowAddChecklist(false);
                }}>
                <Icon name='X' variant='l' />
              </TouchableOpacity>
            </Box>
          )}
        </Box>
      )}
    </Formik>
  );
};

import { getDocumentAsync } from 'expo-document-picker';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import { Alert } from '@components/Alert';
import { LocalFile } from '@graphql/generated';
import { fileToLocalFile } from '@utils/fileUtils';
import { isValidExtension } from '@utils/fileValidationUtils';
import { selectFiles } from '@utils/selectFiles';
import { selectImageFromGallery } from '@utils/selectImage';

export const useFilePicker = () => {
  const { t } = useTranslation();
  return {
    launchImageSelection: (
      selectionLimit?: ImageSelectionOptions['selectionLimit']
    ) =>
      launchImageSelection({
        denyAccessTitle: t('accessDenied.camera.title'),
        denyAccessMessage: t('accessDenied.camera.message'),
        selectionLimit,
      }),
    launchFileSelection,
  };
};

type ImageSelectionOptions = {
  denyAccessTitle: string;
  denyAccessMessage: string;
  selectionLimit?: number;
};

const launchImageSelection = (options: ImageSelectionOptions) => {
  return Platform.select({
    web: async ({ selectionLimit }: ImageSelectionOptions) =>
      launchFileSelectionWeb(selectionLimit, 'photo'),
    default: async ({ selectionLimit }: ImageSelectionOptions) => {
      const images = await selectImageFromGallery(selectionLimit);
      return validateFiles(images);
    },
  })(options);
};

const launchFileSelection = (selectionLimit?: number) => {
  return Platform.select({
    web: async (selectionLimit?: number) =>
      launchFileSelectionWeb(selectionLimit, 'file'),
    default: async (selectionLimit?: number) => {
      const files = await selectFiles(!!selectionLimit && selectionLimit > 1);
      return validateFiles(files); // Perform validation
    },
  })(selectionLimit);
};

const launchFileSelectionWeb = async (
  selectionLimit?: number,
  type: 'photo' | 'file' = 'photo'
): Promise<LocalFile[] | undefined> => {
  const result = await getDocumentAsync({
    multiple: selectionLimit === undefined || selectionLimit > 1,
    type: type === 'photo' ? 'image/*' : undefined,
  });
  if (result.type === 'cancel' || !result.output) {
    return;
  }

  const files = Array.from(result.output);

  if (files && (!selectionLimit || files?.length <= selectionLimit)) {
    const localFiles = await Promise.all(
      files.map((file) => fileToLocalFile(file))
    );
    return validateFiles(localFiles); // Perform validation
  } else if (selectionLimit && files.length > selectionLimit) {
    Alert.alert(`Max selection limit of ${selectionLimit} has been reached`);
  }

  return;
};

// Validation function to check if all selected files are valid
const validateFiles = (
  files: LocalFile[] | undefined
): LocalFile[] | undefined => {
  if (!files) return;

  const invalidFiles = files.filter((file) => !isValidExtension(file.name));
  if (invalidFiles.length > 0) {
    Alert.alert(
      'Upload failed',
      'The selected file type is not supported for upload. Please check the allowed file types.'
    );

    return undefined;
  }

  return files; // Return files if they are valid
};

import { useRoute } from '@react-navigation/native';
import * as Sentry from '@sentry/react-native';
import * as Notifications from 'expo-notifications';
import debounce from 'lodash.debounce';
import React, {
  memo,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AppState, Platform } from 'react-native';

import {
  useMessageUpdatedSubscription,
  useListMessagesQuery,
  Message,
  useMarkChatsReadMutation,
  MessageDetailFragmentDoc,
  Document,
  DocumentAttributes,
  useListDraftDocumentsQuery,
  ListDraftDocumentsDocument,
  useUpdateMessageIndividualMutation,
  MessageEdge,
  ListMessagesDocument,
  MessageConnection,
  MessageProject,
  MessageTask,
  Chat,
  useGetChatQuery,
} from '@graphql/generated';
import useActiveChat from '@hooks/useActiveChat';
import { useApolloClient } from '@hooks/useApolloClient';
import useFileProcessor from '@hooks/useFileProcessor';

import ChatDetail from './ChatDetail';
import { MyChatDetail } from '../Chats/MyChatDetail';

type Props = {
  chatId?: string;
  messageCursor?: string;
  messageId?: string;
  showHeader?: boolean;
};

const ChatMessageData: React.FC<Props> = ({
  chatId,
  messageCursor = '',
  messageId,
  showHeader,
}) => {
  const appState = useRef(AppState.currentState);
  const [appStateVisible, setAppStateVisible] = useState(appState.current);
  const [messageBeingProcessed, setMessageBeingProcessed] = useState<
    string | null
  >();
  const [refreshing, setRefreshing] = useState(false);
  const { processFiles } = useFileProcessor();
  let showKeyboardFocus;
  if (Platform.OS !== 'web') {
    const route = useRoute();
    showKeyboardFocus = route?.params?.showKeyboardFocus || false;
  }
  // To be compatible with web, b/c web is relying on the useActiveChat hook
  const {
    activeChatId: aid,
    activeChat,
    isOpenChat,
    activeMessageCursor,
    setActiveChat,
  } = useActiveChat();
  const { id } = activeChat || { id: aid };
  const activeChatId = chatId || id;
  const [chatIdsToClear, setChatIdsToClear] = useState<string[]>([]);

  const { data: chatData } =
    Platform.OS === 'web'
      ? useGetChatQuery({
          fetchPolicy: 'cache-and-network',
          variables: { id: activeChatId as string },
          skip: !activeChatId,
        })
      : { data: { getChat: {} } };

  const { getChat: chat } = chatData || {};

  const clearPresentedNotifications = async (chatIds: Chat['id'][]) => {
    const presentedNotifications =
      await Notifications.getPresentedNotificationsAsync();

    let remainingChatIds = chatIds;

    chatIds.forEach((chatId) => {
      const url = `/chat/${chatId}`;
      const notificationsToDismiss = presentedNotifications.filter((n) =>
        (n.request.content?.data?.url ?? '').startsWith(url)
      );

      if (notificationsToDismiss.length < 1) {
        remainingChatIds = chatIds.filter((item) => item !== chatId);
      }
      notificationsToDismiss.forEach(async (n) => {
        await Notifications.dismissNotificationAsync(n.request.identifier);
      });
    });

    if (remainingChatIds.length < 1) return Promise.resolve();

    // NOTE: Expo delivers 25 at a time...process again until the list is cleared.
    clearPresentedNotifications(remainingChatIds);
  };

  useEffect(() => {
    if (Platform.OS === 'web' && chat && !activeChat) {
      setActiveChat(chat);
    }
  }, [chat]);

  useEffect(() => {
    const clear = async () => {
      await clearPresentedNotifications(chatIdsToClear);
      setChatIdsToClear([]);
    };

    if (chatIdsToClear.length > 0) {
      clear();
    }
  }, [chatIdsToClear]);

  const cursor = messageCursor || activeMessageCursor;

  const sortMessages = (
    a?: Pick<Message, 'publishedAt' | 'createdAt'> | null,
    b?: Pick<Message, 'publishedAt' | 'createdAt'> | null
  ) => {
    if (!a && !!b) return -1;
    if (!b && !!a) return 1;
    if (!a && !b) return 0;
    return new Date(a?.publishedAt || a?.createdAt) <
      new Date(b?.publishedAt || b?.createdAt)
      ? 1
      : -1;
  };

  const [markChatsRead] = useMarkChatsReadMutation();

  const perPageCount = 20;

  const firstFetchPolicy = 'cache-and-network';

  const {
    data: listMessagesData,
    fetchMore,
    loading,
    refetch,
  } = useListMessagesQuery({
    fetchPolicy: firstFetchPolicy,
    nextFetchPolicy: firstFetchPolicy,
    refetchWritePolicy: 'overwrite',
    variables: {
      chatId: activeChatId as string,
      first: perPageCount,
      // before: cursor as string,
      // after: cursor as string,
      // last: perPageCount,
    },
    onCompleted: () => {
      setRefreshing(false);
      if (Platform.OS !== 'web' && activeChatId) {
        setChatIdsToClear((prev) => [...prev, activeChatId!]);
      }
    },
    onError: () => {
      setRefreshing(false);
    },
    skip: !activeChatId,
  });

  useEffect(() => {
    if (activeChatId) {
      markChatsRead({
        variables: { attributes: { chatIds: [activeChatId] } },
      });
    }
  }, [activeChatId]);

  const { listMessages } = listMessagesData || {};
  const { edges, pageInfo } = listMessages || { edges: [], pageInfo: null };

  const { client } = useApolloClient();

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    refetch();
  }, []);

  // NOTE: null is returned when initially subscribing to the messageCreated subscription.
  // graphql-ruby returns :no_response by default and Apollo expects a value.
  const sortChatMessageEdges = (edges: MessageEdge[]) => {
    const publishedMessages = (edges || [])
      .filter((item): item is MessageEdge => !!item)
      .map((item) => item.node);
    const messages: Message[] = publishedMessages
      .filter((item): item is Message => !!item?.clientId)
      .sort(sortMessages);
    return messages;
  };

  const messages = useMemo(() => {
    return sortChatMessageEdges(edges || []);
  }, [edges]);

  useMessageUpdatedSubscription({
    variables: { chatId: activeChatId as string },
    skip: !activeChatId,
    onData: ({ data: subscriptionData, client }) => {
      const messageUpdated = subscriptionData.data?.messageUpdated;

      if (!messageUpdated) return;

      const { cache } = client;

      const newMessage = (messageUpdated.edges || [])[0];

      if (!newMessage) return;

      const newMessageNode = newMessage.node;
      if (!newMessageNode) return;

      cache.updateFragment(
        {
          id: cache.identify(newMessageNode),
          fragment: MessageDetailFragmentDoc,
          fragmentName: 'MessageDetail',
        },
        (data) => {
          if (!data) {
            return { ...newMessageNode, failedAt: null };
          }
          return { ...data, ...newMessageNode, failedAt: null };
        }
      );

      cache.updateQuery(
        {
          query: ListMessagesDocument,
          variables: {
            chatId: activeChatId,
          },
        },
        (data) => {
          if (!data) return;

          const { listMessages }: { listMessages: MessageConnection } = data;
          const newMessageIdentity = cache.identify(newMessageNode);

          const edgeExists = (listMessages.edges || []).some((item) => {
            if (!item || !item.node) return false;
            const itemIdentity = cache.identify(item.node);
            return itemIdentity == newMessageIdentity;
          });

          if (edgeExists) return undefined;

          const newEdges = data.listMessages.edges.concat(newMessage);

          return {
            listMessages: {
              ...data.listMessages,
              edges: newEdges,
            },
          };
        }
      );

      if (newMessageNode.chatId) {
        if (Platform.OS === 'web' && isOpenChat) {
          debouncedUpdateChatsRead(newMessageNode.chatId);
        } else {
          debouncedUpdateChatsRead(newMessageNode.chatId);
        }
      }
    },
  });

  const updateChatsRead = (chatId: string) => {
    markChatsRead({
      variables: {
        attributes: {
          chatIds: [chatId],
        },
      },
      onCompleted: () => {
        refetch();
      },
    });
  };

  const debouncedUpdateChatsRead = useMemo(
    () => debounce(updateChatsRead, 500),
    []
  );

  const [updateMessageIndividual] = useUpdateMessageIndividualMutation();

  const { data: listDraftDocuments } = useListDraftDocumentsQuery({
    variables: {
      chatId: activeChatId || '',
    },
    fetchPolicy: 'cache-only',
    skip: !activeChatId,
  });

  useEffect(() => {
    uploadDocument();
  }, [listDraftDocuments]);

  const uploadDocument = () => {
    const documents: Document[] = (
      listDraftDocuments?.listDraftDocuments || []
    ).filter(
      (doc: Pick<Document, 'id' | 'clientId'>) => doc.id == doc.clientId
    );

    if (documents.length < 1) {
      return;
    }

    const documentMap = new Map(
      documents.map((doc) => [doc.messageClientId, doc])
    ); // Create a map for quick lookup
    const filteredMessage: Message[] = messages.filter((msg) =>
      documentMap.has(msg?.clientId)
    ); // Filter messages with a matching clientId
    const message = filteredMessage[0]; // Get the first matched message

    if (!message) {
      // TODO handle removing this doc from the query
      if (__DEV__) console.log('================no message===============');
      return;
    }
    if (messageBeingProcessed === message.clientId) return;

    setMessageBeingProcessed(message.clientId);

    const documentsToProcess = documents.filter(
      (doc) => doc.messageClientId === message.clientId
    );
    processFiles(documentsToProcess)
      .then(async (response) => {
        const messageProjects = (message.attachments || []).filter(
          (item) => item.__typename === 'MessageProject'
        ) as MessageProject[];

        const messageTasks = (message.attachments || []).filter(
          (item) => item.__typename === 'MessageTask'
        ) as MessageTask[];

        const documentAttributes = await documentsToProcess.map((item) => {
          const {
            contentType,
            clientId: documentClientId,
            name,
            isImage,
            isAudio,
            duration,
          } = item;
          const { blobId } =
            response.find(
              (uploadResultItem) =>
                uploadResultItem.clientId === documentClientId
            ) || {};

          return {
            name,
            contentType,
            blobId,
            isImage,
            clientId: documentClientId,
            isAudio,
            duration,
            projectIds: messageProjects.map((item) => item.project?.id),
            taskIds: messageTasks.map((item) => item.task?.id),
          } as DocumentAttributes;
        });

        await updateMessageIndividual({
          variables: {
            clientId: message.clientId,
            attributes: {
              chatId: message.chatId,
              documents: documentAttributes,
              publish: true,
              messageProjects: messageProjects.map((item) => ({
                projectId: item?.project?.id,
                authorId: item.authorId,
              })),
              messageTasks: messageTasks.map((item) => ({
                taskId: item?.task?.id,
                authorId: item.authorId,
              })),
            },
          },
          onCompleted: () => {
            client?.cache.updateQuery(
              {
                query: ListDraftDocumentsDocument,
                variables: {
                  chatId: activeChatId,
                },
              },
              (data) => {
                return {
                  listDraftDocuments: [
                    ...(data?.listDraftDocuments || []).filter(
                      (d: Document) =>
                        !documents.some((fd) => d.clientId === fd.clientId)
                    ),
                  ],
                };
              }
            );
          },
          onError: (err) => {
            Sentry.captureException(
              new Error(
                'updateMessageIndividual onError : ' + JSON.stringify(err)
              )
            );
          },
        });
      })
      .catch((err) => console.error(err))
      .finally(() => setMessageBeingProcessed(null));
  };

  const fetchAfterCursor = useCallback(() => {
    if (loading || !pageInfo?.hasNextPage) {
      return;
    }

    let xcursor = pageInfo.endCursor;
    const rtn = edges?.filter(
      (n) => n?.node?.cursor == messages[messages.length - 1].cursor
    );
    // when there is cache data, the pageinfo's end cursor is not right, so to do reset here.
    if ((rtn?.length ?? 0) > 0 && xcursor != rtn![0]!.cursor) {
      xcursor = rtn![0]!.cursor;
    }

    fetchMore({
      variables: {
        chatId: activeChatId,
        after: xcursor,
        first: perPageCount,
      },
    });
  }, [edges, pageInfo, activeChatId, loading]);

  useEffect(() => {
    if (activeChatId && appStateVisible == 'active') {
      // Note: This refetch solves the problem of messages missing when coming in via notifications
      //   refetch();
    }
  }, [activeChatId, appStateVisible]);

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === 'active'
      )
        appState.current = nextAppState;
      setAppStateVisible(appState.current);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  if (!activeChatId) return null;

  if (Platform.OS === 'web')
    return (
      <ChatDetail
        hasNextPage={pageInfo?.hasNextPage ?? true}
        messages={messages}
        chatId={activeChatId}
        fetchBeforeCursor={() => {}}
        fetchAfterCursor={fetchAfterCursor}
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        messageCursor={cursor}
      />
    );

  return (
    <MyChatDetail
      hasNextPage={pageInfo?.hasNextPage ?? true}
      messages={messages}
      showKeyboardFocus={showKeyboardFocus}
      showHeader={showHeader}
      chatId={activeChatId}
      messageId={messageId}
      fetchBeforeCursor={() => {}}
      fetchAfterCursor={fetchAfterCursor}
      refreshing={refreshing}
      loading={loading}
      onRefresh={onRefresh}
      messageCursor={cursor}
    />
  );
};

export default Sentry.withProfiler(memo(ChatMessageData));

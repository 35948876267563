import { WatchQueryFetchPolicy } from '@apollo/client';
import { useState } from 'react';

import { useListRecentProjectsTasksQuery } from '@graphql/generated';
/**
 * Retrieves a list of project tags
 */

interface ListProjectsTasksRecentQueryProps {
  archivesOnly?: boolean;
  onCompleted?: () => void;
  onError?: () => void;
  chatId?: string;
  fetchPolicy?: WatchQueryFetchPolicy;
  excludeCompleted?: boolean;
  collaboratorsOnly?: boolean;
}

export const useListProjectsTasksRecentFromQuery = (
  props?: ListProjectsTasksRecentQueryProps
) => {
  const {
    onCompleted = undefined,
    onError = undefined,
    chatId = undefined,
    excludeCompleted = false,
    collaboratorsOnly = false,
    fetchPolicy = 'cache-and-network',
  } = props || {};

  const [, setRefreshing] = useState(false);

  const { data, loading, error } = useListRecentProjectsTasksQuery({
    variables: {
      chatId,
      excludeCompleted,
      collaboratorsOnly,
    },
    refetchQueries: [
      { query: 'listRecentProjectsTasks', variable: { chatId: chatId } },
    ],
    fetchPolicy: fetchPolicy,
    onCompleted: () => {
      setRefreshing(false);
      onCompleted && onCompleted();
    },
    onError: () => {
      setRefreshing(false);
      onError && onError();
    },
  });

  const { listRecentProjectsTasks } = data || {
    listRecentProjectsTasks: undefined,
  };

  // const fetchMoreFromCursor = () => {
  //   if (!projects || !pageInfo?.hasNextPage || loading) return;

  //   const cursor = pageInfo.endCursor;
  //   fetchMore({
  //     variables: {
  //       after: cursor,
  //     },
  //   });
  // };

  // const { edges = [], pageInfo } = listRecentProjectsTasks || { edges: [] };
  // // TODO: This needs to be more properly typed to the returned graphql value.
  // const projects =
  //   (edges || []).reduce((acc, curr) => {
  //     if (curr && curr.node && curr.node !== undefined) {
  //       return [...acc, curr.node as ProjectTagType];
  //     } else {
  //       return acc;
  //     }
  //   }, [] as ProjectTagType[]) || [];

  // pagination returns duplicate projects when projects are named the same resulting in duplicate key errors
  // can be removed when pagination is fixed

  // const projectIds = projects.map((t) => t.id);
  // const filterOutDuplicates = projects.filter((p, index) => {
  //   return !projectIds.includes(p.id.toString(), index + 1);
  // });

  return {
    recentProjectsTasks: listRecentProjectsTasks,
    loading,
    error,
  };
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Text } from '../Restyle';
import Icon from '../shared/Icon/Icon';

const ForwardMessage: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation('shared');

  return (
    <Box
      backgroundColor='grey01'
      paddingHorizontal='xs'
      paddingTop='xs'
      borderRadius='xs'
      marginTop='xxs'>
      <Box flexDirection='row'>
        <Icon name='Forward' color='textSecondary' variant='m' />
        <Text variant='labelSmall' color='textSecondary' marginLeft='xxs'>
          {t('forwarded')}
        </Text>
      </Box>
      {children}
    </Box>
  );
};

export default ForwardMessage;

import { useNavigation, CommonActions } from '@react-navigation/native';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Platform } from 'react-native';

import useActiveChat, { ActiveChat } from '@hooks/useActiveChat';
import { AppStackScreenProps } from '@navigation/app-stack';
import { ChatsStackParamList } from '@navigation/chats/chats-stack';
export enum ChatDrawerScreen {
  closed,
  details,
  settingsIndividual,
  settingsGroup,
  newChat,
  newGroupChat,
  completeTaskModal,
  assignTaskModal,
}

type ChatDrawerScreenNavMap = Record<
  ChatDrawerScreen,
  keyof ChatsStackParamList | undefined
> & { [ChatDrawerScreen.closed]: undefined };
const chatDrawerScreenNavMap: ChatDrawerScreenNavMap = {
  [ChatDrawerScreen.closed]: undefined,
  [ChatDrawerScreen.details]: 'chat-detail',
  [ChatDrawerScreen.settingsIndividual]: 'individual-chat-settings',
  [ChatDrawerScreen.settingsGroup]: 'group-chat-settings',
  [ChatDrawerScreen.newChat]: 'new-chat',
  [ChatDrawerScreen.newGroupChat]: 'create-group-stack',
  [ChatDrawerScreen.completeTaskModal]: 'task-modal',
  [ChatDrawerScreen.assignTaskModal]: 'task-modal',
};

type WebDrawerContext = {
  screen: ChatDrawerScreen;
  navigateToScreen: (screen: ChatDrawerScreen) => void;
  chatExpanded: boolean;
  setChatExpanded: (chatExpanded: boolean) => void;
  toggleChatExpanded: () => void;
  isBottomBannerShow: boolean;
  setIsBottomBannerShow: (isBottomBannerShow: boolean) => void;
  chatDrawerWidth: number;
  setChatDrawerWidth: (width: number) => void;
  chatListOpen: boolean;
  setChatListOpen: (open: boolean) => void;
  toggleChatListOpen: () => void;
  shouldChatListOpen: boolean;
  setShouldChatListOpen: (open: boolean) => void;
  isCreateProjectOpen: boolean;
  setIsCreateProjectOpen: (isBottomBannerShow: boolean) => void;
  editProjectId: string;
  setEditProjectId: (editProjectId: string) => void;
  isTaskWebPanelOpen: boolean;
  setIsTaskWebPanelOpen: (isBottomBannerShow: boolean) => void;
  isTaskWebPanelMaximize: boolean;
  setIsTaskWebPanelMaximize: (a: boolean) => void;
  idTaskDetailOpen: string;
  setIdTaskDetailOpen: (idtask: string) => void;
  idProjectOfTaskDetailOpen: string;
  setIdProjectOfTaskDetailOpen: (idproject: string) => void;
  mainPanelWidth: number;
  setMainPanelWidth: (width: number) => void;
};

const initialWebDrawerContext: WebDrawerContext = {
  screen: ChatDrawerScreen.closed,
  navigateToScreen: () => void 0,
  chatExpanded: false,
  setChatExpanded: () => void 0,
  toggleChatExpanded: () => void 0,
  isBottomBannerShow: true,
  setIsBottomBannerShow: () => void 0,
  chatDrawerWidth: 0,
  setChatDrawerWidth: () => void 0,
  chatListOpen: true,
  setChatListOpen: () => void 0,
  toggleChatListOpen: () => void 0,
  shouldChatListOpen: false,
  setShouldChatListOpen: () => void 0,
  isCreateProjectOpen: false,
  setIsCreateProjectOpen: () => void 0,
  editProjectId: '',
  setEditProjectId: () => void 0,
  isTaskWebPanelOpen: false,
  setIsTaskWebPanelOpen: () => void 0,
  isTaskWebPanelMaximize: false,
  setIsTaskWebPanelMaximize: () => void 0,
  idTaskDetailOpen: '',
  setIdTaskDetailOpen: () => void 0,
  idProjectOfTaskDetailOpen: '',
  setIdProjectOfTaskDetailOpen: () => void 0,
  mainPanelWidth: 580,
  setMainPanelWidth: () => void 0,
};

const WebDrawerContext = createContext<WebDrawerContext>(
  initialWebDrawerContext
);

/**
 * Provides state and manipulation for web chat drawers
 */
export const WebDrawerProvider = ({
  children,
  screen: screenProp,
}: {
  children: ReactNode;
  /** The screen to load for the drawer */
  screen?: ChatDrawerScreen;
}) => {
  const [chatListOpen, setChatListOpen] = useState(true);
  const [chatExpanded, setChatExpanded] = useState(false);
  const [screen, setScreen] = useState(screenProp || ChatDrawerScreen.closed);
  const [isBottomBannerShow, setIsBottomBannerShow] = useState(false);
  const [chatDrawerWidth, setChatDrawerWidth] = useState(0);
  const [shouldChatListOpen, setShouldChatListOpen] = useState(false);
  const [isCreateProjectOpen, setIsCreateProjectOpen] = useState(false);
  const [editProjectId, setEditProjectId] = useState('');

  const [isTaskWebPanelOpen, setIsTaskWebPanelOpen] = useState(false);
  const [isTaskWebPanelMaximize, setIsTaskWebPanelMaximize] = useState(false);
  const [idTaskDetailOpen, setIdTaskDetailOpen] = useState('');
  const [idProjectOfTaskDetailOpen, setIdProjectOfTaskDetailOpen] =
    useState('');
  const [mainPanelWidth, setMainPanelWidth] = useState(0);

  useEffect(() => {
    screenProp && setScreen(screenProp);
  }, [screenProp]);

  useEffect(() => {
    async function getScreenInfo() {
      const info = window.sessionStorage.getItem('screenInfo');
      if (info) {
        const c = JSON.parse(info);
        setScreen(c.screen);
        setChatExpanded(c.chatExpanded);
        setIsBottomBannerShow(c.isBottomBannerShow);
        setChatDrawerWidth(c.chatDrawerWidth);
        setChatListOpen(c.chatListOpen);
        setShouldChatListOpen(c.shouldChatListOpen);
        setIsCreateProjectOpen(c.isCreateProjectOpen);
        setEditProjectId(c.editProjectId);
        setIsTaskWebPanelOpen(c.isTaskWebPanelOpen);
        setIdTaskDetailOpen(c.idTaskDetailOpen);
        setIsTaskWebPanelMaximize(c.isTaskWebPanelMaximize);
        setMainPanelWidth(c.mainPanelWidth);
        setIdProjectOfTaskDetailOpen(c.idProjectOfTaskDetailOpen);
      }
    }
    getScreenInfo();
  }, []);

  useEffect(() => {
    // https://tasktag.atlassian.net/browse/TA-4012
    // 5. When the page is reloaded, everything is reset: the sidebar opens from a collapsed state, the task closes, the chat opens the main screen
    function saveScreenInfo() {
      const info = {
        screen,
        chatExpanded,
        isBottomBannerShow,
        chatDrawerWidth,
        chatListOpen,
        shouldChatListOpen,
        isCreateProjectOpen,
        editProjectId,
        isTaskWebPanelOpen,
        idTaskDetailOpen,
        isTaskWebPanelMaximize,
        mainPanelWidth,
        idProjectOfTaskDetailOpen,
      };
      window.sessionStorage.setItem('screenInfo', JSON.stringify(info));
    }

    saveScreenInfo();
  }, [
    screen,
    chatExpanded,
    isBottomBannerShow,
    chatDrawerWidth,
    chatListOpen,
    shouldChatListOpen,
    isCreateProjectOpen,
    editProjectId,
    isTaskWebPanelOpen,
    idTaskDetailOpen,
    isTaskWebPanelMaximize,
    mainPanelWidth,
    idProjectOfTaskDetailOpen,
  ]);

  const toggleChatListOpen = () => {
    setChatListOpen((o) => !o);
  };

  const toggleChatExpanded = () => {
    // if (chatExpanded) {
    //   setChatListOpen(false);
    // } else {
    //   setChatListOpen(true);
    // }
    setChatExpanded((o) => !o);
  };

  const value = useMemo(
    () => ({
      screen,
      navigateToScreen: setScreen,
      chatExpanded,
      setChatExpanded,
      toggleChatExpanded,
      isBottomBannerShow,
      setIsBottomBannerShow,
      chatDrawerWidth,
      setChatDrawerWidth,
      chatListOpen,
      setChatListOpen,
      toggleChatListOpen,
      shouldChatListOpen,
      setShouldChatListOpen,
      isCreateProjectOpen,
      setIsCreateProjectOpen,
      editProjectId,
      setEditProjectId,
      isTaskWebPanelOpen,
      setIsTaskWebPanelOpen,
      isTaskWebPanelMaximize,
      setIsTaskWebPanelMaximize,
      idTaskDetailOpen,
      setIdTaskDetailOpen,
      idProjectOfTaskDetailOpen,
      setIdProjectOfTaskDetailOpen,
      mainPanelWidth,
      setMainPanelWidth,
    }),
    [
      screen,
      chatExpanded,
      isBottomBannerShow,
      chatDrawerWidth,
      chatListOpen,
      shouldChatListOpen,
      isCreateProjectOpen,
      editProjectId,
      isTaskWebPanelOpen,
      idTaskDetailOpen,
      isTaskWebPanelMaximize,
      mainPanelWidth,
      idProjectOfTaskDetailOpen,
    ]
  );

  return (
    <WebDrawerContext.Provider value={value}>
      {children}
    </WebDrawerContext.Provider>
  );
};

export const useWebDrawer = () => {
  const context = useContext(WebDrawerContext);

  if (!context) {
    throw new Error(
      'useWebDrawerContext can only be used within a WebDrawerProvider'
    );
  }

  return context;
};

/**
 * Returns an object containing the state of the web drawer.
 * @returns Object containing the state of the web drawer.
 */
export const useWebDrawerState = () => {
  const {
    screen,
    chatExpanded,
    isBottomBannerShow,
    chatDrawerWidth,
    chatListOpen,
    shouldChatListOpen,
  } = useWebDrawer();

  return {
    screen,
    chatExpanded,
    isBottomBannerShow,
    chatDrawerWidth,
    chatListOpen,
    shouldChatListOpen,
  };
};

/**
 * Allows navigation to either the chat details drawer or general navigation.
 *
 * When navigating to a details screen, do not set an active chat. This is handled internally.
 *
 * @returns A method that allows navigation to the chat details drawer or general navigation.
 */
export const useNavigateToChatDrawer = (): ((
  options:
    | {
        screen: Exclude<
          ChatDrawerScreen,
          | ChatDrawerScreen.details
          | ChatDrawerScreen.completeTaskModal
          | ChatDrawerScreen.assignTaskModal
        >;
        activeChat?: never;
        messageCursor?: string;
        chatId?: never;
        messageId?: string;
        resetStack?: boolean;
      }
    | (
        | {
            screen:
              | ChatDrawerScreen.details
              | ChatDrawerScreen.completeTaskModal
              | ChatDrawerScreen.assignTaskModal;
            activeChat?: never;
            messageCursor?: string;
            messageId?: string;
            chatId: ActiveChat['id'];
            resetStack?: boolean;
          }
        | {
            screen:
              | ChatDrawerScreen.details
              | ChatDrawerScreen.completeTaskModal
              | ChatDrawerScreen.assignTaskModal;
            activeChat: ActiveChat;
            messageCursor?: string;
            chatId?: never;
            messageId?: string;
            resetStack?: boolean;
          }
      )
) => void) => {
  const context = useContext(WebDrawerContext);
  const navigation =
    useNavigation<AppStackScreenProps<'chats-stack'>['navigation']>();

  const { setActiveChat, setActiveMessageCursor } = useActiveChat();

  // TODO: Migrate this inside the context such that we do not need to expose the
  // `context.navigateToScreen` method.
  return ({
    screen,
    activeChat,
    messageCursor,
    messageId,
    chatId,
    resetStack,
  }) => {
    if (context && Platform.OS === 'web') {
      activeChat && setActiveChat(activeChat);
      messageCursor && setActiveMessageCursor(messageCursor);
      context.navigateToScreen(screen);
    } else if (screen !== ChatDrawerScreen.closed) {
      const navScreen = chatDrawerScreenNavMap[screen];

      if (navScreen) {
        if (screen === ChatDrawerScreen.completeTaskModal) {
          navigation.navigate('chats-stack', {
            screen: 'task-modal',
            params: { complete: true },
          });
        } else {
          if (!resetStack) {
            // TODO: Fix type error
            navigation.navigate('chats-stack', {
              screen: navScreen,
              params: {
                id: chatId ?? activeChat?.id,
                messageCursor,
                messageId,
              },
            });
          } else {
            navigation.dispatch(
              CommonActions.reset({
                index: 0,
                routes: [
                  {
                    name: 'chat-detail',
                    params: {
                      id: chatId ?? activeChat?.id,
                      messageCursor,
                      messageId,
                    },
                  },
                ],
              })
            );
          }
        }
      }
    }
  };
};

import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';

type GoogleReCaptcha = {
  showError?: boolean;
  onCaptchaSuccess: (name: string) => void;
};

const GoogleReCaptcha: React.FC<GoogleReCaptcha> = ({
  showError,
  onCaptchaSuccess,
}) => {
  const onVerify = (token: string) => {
    onCaptchaSuccess(token);
  };

  return (
    <>
      <ReCAPTCHA
        baseUrl={process.env.G_CAPTCHA_BASE_URL}
        sitekey={process.env.G_CAPTCHA_KEY}
        onChange={onVerify}
      />
      {showError && (
        <Box flexDirection='row' alignItems='center' marginRight='m'>
          <Icon
            name='AlertTriangle'
            color='alertRed'
            variant='s'
            marginRight='xxs'
          />
          <Text variant='error'>Please select captcha</Text>
        </Box>
      )}
    </>
  );
};

export default GoogleReCaptcha;

/* eslint-disable no-nested-ternary */
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, TouchableOpacity } from 'react-native';
import Highlighter from 'react-native-highlight-words';

import Avatar from '@components/Avatar/Avatar';
import ChatImages from '@components/Chat/ChatImages';
import DocumentCard from '@components/Chat/DocumentCard';
import MessageParts from '@components/Chat/MessageParts';
import TagsCollection from '@components/Chat/TagsCollection';
import { VoiceCard } from '@components/Chat/VoiceCard';
import RadioButton from '@components/RadioButton/RadioButton';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import {
  Attachment,
  Document,
  Chat,
  LocalFile,
  Message,
} from '@graphql/generated';
import useActiveChat from '@hooks/useActiveChat';
import useChatAvatar from '@hooks/useChatAvatar';
import useMe from '@hooks/useMe';
import { usePreviewDocuments } from '@hooks/usePreviewDocuments';
import theme from '@themes/theme';
import { openInNewTab } from '@utils/fileUtils';
import { triggersConfigPlain } from '@utils/mentions';
import { generateTagCollections } from '@utils/tagCollectionService';

type ChatCardProps = {
  messageData?: Message[];
  chatCard: Chat;
  filterVal?: string;
  onPress?: (chat: Chat, messageCursor?: string) => void;
  onMutePress?: (chat: Chat) => void;
  onPinPress?: (chat: Chat) => void;
  isEditing: boolean;
  isSelected?: boolean;
  highlightedBody?: string;
  highlightedTitle?: string;
  collapsed?: boolean;
  onRightClick?: (chatCard: Chat, mouseX: number, mouseY: number) => void;
  contentHeightLimit?: boolean;
  isFromChatSearch?: boolean;
};

const ChatCard: React.FC<ChatCardProps> = ({
  messageData,
  chatCard: chat,
  filterVal = '',
  onPress,
  onMutePress,
  onPinPress,
  isEditing,
  isSelected,
  highlightedBody,
  highlightedTitle,
  collapsed = false,
  onRightClick,
  contentHeightLimit,
  isFromChatSearch = false,
}) => {
  const { t } = useTranslation('format');
  const {
    id: chatId,
    avatar,
    name,
    settings,
    messageBody,
    recentActivityAt,
    messageIcon,
    isGroupChat,
    friendRequestAccepted,
    owner,
  } = chat;
  const { activeChat } = useActiveChat();
  const unreadCount = chat?.unreadCount || 0;
  const [bodyWidth, setBodyWidth] = useState(0);
  const { getChatAvatar, getChatAvatarLabel } = useChatAvatar();
  const navigation = useNavigation();
  const { setPreviewDocuments, setActiveIndex, setTagsCollections } =
    usePreviewDocuments();
  const tagsByAuthorCollections =
    messageData && generateTagCollections(messageData[0]?.attachments || []);
  const isDocumentType = (item: Attachment): item is Document =>
    item.__typename === 'Document';
  const [isHovered, setIsHovered] = useState(false);
  const { me } = useMe();
  const isSenderFriendRequest =
    !friendRequestAccepted &&
    owner.id === me?.id &&
    !isGroupChat &&
    !isFromChatSearch;
  const { t: u } = useTranslation();

  const mainRef = useRef<HTMLElement>(null);

  const handleContextMenu = (e: {
    preventDefault: () => void;
    pageX: any;
    pageY: any;
  }) => {
    e.preventDefault();
    onRightClick && onRightClick(chat, e.pageX, e.pageY);
  };

  useEffect(() => {
    const element = mainRef.current;
    if (element) {
      element.addEventListener('contextmenu', handleContextMenu);
      return () => {
        element.removeEventListener('contextmenu', handleContextMenu);
      };
    }
  }, [handleContextMenu]);

  const documentAttachments: Document[] =
    messageData &&
    (messageData[0]?.attachments?.filter((item) => {
      return isDocumentType(item);
    }) as Document[]);

  const audioAttachments = documentAttachments?.filter((item) => item.isAudio);
  const imageAttachments = documentAttachments?.filter((item) => item.isImage);
  const fileAttachments = documentAttachments?.filter(
    (item) => !item.isImage && !item.isAudio
  );
  const hideIcon =
    imageAttachments ?? fileAttachments ?? audioAttachments ?? false;
  const messageContent = (
    <Box>
      {audioAttachments?.map((document) => (
        <Box marginBottom='xs' key={document.id}>
          <VoiceCard
            onLongPress={() => {
              void 0;
            }}
            key={document.id}
            voice={document}
          />
        </Box>
      ))}
      {fileAttachments?.map((document) => (
        <Box marginBottom='xxs' key={document.id}>
          <DocumentCard
            filterVal={filterVal}
            key={document.id}
            document={document}
            onPress={() => {
              if (Platform.OS === 'web') return openInNewTab(document);

              setPreviewDocuments([{ ...document }]);
              navigation.navigate('file-preview');
            }}
            onLongPress={() => {
              void 0;
            }}
          />
        </Box>
      ))}

      {imageAttachments?.length > 0 && (
        <Box marginBottom='xxs'>
          <ChatImages
            isForwarded={false}
            onPress={(image: Document | LocalFile) => {
              if (Platform.OS === 'web') return openInNewTab(image);

              setPreviewDocuments(
                imageAttachments.map((item) => ({
                  ...item,
                  owner: messageData?.author,
                }))
              );
              setActiveIndex(
                imageAttachments.findIndex((i) => i.id === image.id)
              );
              setTagsCollections(tagsByAuthorCollections);
              navigation.navigate('images-preview');
            }}
            onLongPress={() => {
              void 0;
            }}
            list={imageAttachments}
          />
        </Box>
      )}

      {tagsByAuthorCollections?.map((tagCollections, index) => {
        return (
          <Box key={index} marginBottom='xs'>
            {tagCollections?.map((item, index) => {
              const { project, tasks, author } = item;
              return (
                <Box marginBottom='xs' key={index}>
                  <TagsCollection
                    filterVal={filterVal}
                    onLongPress={() => void 0}
                    tagsCollection={{ project, tasks, author }}
                    showAuthor={false}
                  />
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );

  const getMessageBody = (body: any) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /{@}\[([^\[\]()]+?)\]/g;
    const matches = [...body.matchAll(regex)];
    const modifiedText = matches.reduce((acc, match) => {
      const extractedContent = match[1];
      const replacedString = acc.replace(match[0], extractedContent);
      const output = replacedString.replace(/\([^)]*\)/, '').trim();
      return output;
    }, body);
    return modifiedText;
  };

  const highlightText = (body: string) => {
    if (!filterVal) {
      return body;
    }
    return (
      <Highlighter
        autoEscape
        highlightStyle={{
          backgroundColor: theme.colors.yellowBright,
        }}
        searchWords={filterVal.split(' ') || ''}
        textToHighlight={body}
      />
    );
  };

  return (
    <Box
      bg='white'
      ref={mainRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        backgroundColor:
          activeChat?.id == chatId
            ? theme.colors.greenSecondaryMild
            : isHovered
            ? theme.colors.grey01
            : 'white',
      }}>
      <TouchableOpacity
        key={chatId}
        onPress={() => {
          setIsHovered(false);
          const message = messageData?.[0];
          const { cursor } = message || {};

          onPress && !isSenderFriendRequest && onPress(chat, cursor);
        }}
        style={styles.chatCard}
        accessible={true}
        accessibilityLabel={name ? name : getChatAvatarLabel(chat) || ''}>
        {!collapsed && (
          <Box flexDirection='row' flex={1}>
            {isEditing && (
              <Box justifyContent='center' marginRight='m'>
                <RadioButton
                  onPress={() => onPress && onPress(chat)}
                  isSelected={!!isSelected}
                />
              </Box>
            )}
            <Avatar
              id={chatId}
              avatar={avatar ? avatar : getChatAvatar(chat)}
              label={name ? name : getChatAvatarLabel(chat)}
              skipCache={true}
              isMultiple={isGroupChat}
              disableAvatarPress
              isSenderFriendRequest={isSenderFriendRequest && !isGroupChat}
            />

            <Box
              flex={1}
              flexDirection='column'
              justifyContent='center'
              marginLeft='xs'>
              <Box flexDirection='row' justifyContent='space-between'>
                <Box flex={1}>
                  <Text
                    variant='labelEmphasized'
                    color={isSenderFriendRequest ? 'grey04' : 'textPrimary'}
                    numberOfLines={1}
                    accessibilityLabel='Chat title'>
                    {highlightedTitle ? (
                      <Highlighter
                        autoEscape
                        highlightStyle={{
                          color: theme.colors.black,
                          backgroundColor: theme.colors.yellowBright,
                        }}
                        searchWords={filterVal.split(' ') || ''}
                        textToHighlight={highlightedTitle}
                      />
                    ) : name ? (
                      highlightText(name)
                    ) : (
                      highlightText(getChatAvatarLabel(chat))
                    )}
                  </Text>
                </Box>
                <Box flexDirection='row' alignItems='center' ml='m'>
                  {settings?.mute && (
                    <TouchableOpacity
                      onPress={() => onMutePress && onMutePress(chat)}>
                      <Icon name='Mute' color='grey03' width={16} height={16} />
                    </TouchableOpacity>
                  )}
                  {settings?.pin && (
                    <TouchableOpacity
                      onPress={() => onPinPress && onPinPress(chat)}>
                      <Icon
                        name='Pin'
                        color='grey03'
                        width={16}
                        height={16}
                        marginHorizontal='xxs'
                      />
                    </TouchableOpacity>
                  )}
                  {unreadCount > 0 && (
                    <Box
                      width={unreadCount > 9 ? 21 : 16}
                      height={16}
                      backgroundColor={
                        unreadCount > 0 ? 'greenSecondary' : 'grey03'
                      }
                      borderRadius='xs'>
                      <Text
                        accessibilityLabel='Unread count'
                        variant='metadataSecondary'
                        fontWeight='500'
                        color='white'
                        textAlign='center'>
                        {unreadCount > 99 ? 99 : unreadCount}
                      </Text>
                    </Box>
                  )}
                </Box>
                {isSenderFriendRequest && (
                  <Box
                    backgroundColor='grey02'
                    borderRadius='xxs'
                    paddingHorizontal='xxs'
                    alignSelf='center'>
                    <Text variant='labelTabHeaderText' color='textSecondary'>
                      {u('models:chat.pending')}
                    </Text>
                  </Box>
                )}
              </Box>
              {!highlightedTitle && (
                <Box
                  flexDirection='row'
                  justifyContent='space-between'
                  style={contentHeightLimit ? { height: 24 } : {}}>
                  <Box
                    flex={1}
                    flexDirection='row'
                    alignItems='center'
                    pointerEvents={filterVal ? 'box-none' : 'none'}
                    onLayout={(e) => setBodyWidth(e.nativeEvent.layout.width)}
                    accessibilityLabel='Message preview'>
                    {messageIcon && !hideIcon && (
                      <Icon
                        name={messageIcon}
                        variant='s'
                        color='black'
                        marginRight='xxs'
                      />
                    )}
                    {highlightedBody && bodyWidth ? (
                      <Box>
                        <Text
                          style={{ width: bodyWidth }}
                          variant='bodySecondary'
                          color='textSecondary'
                          numberOfLines={3}
                          marginRight='l'
                          paddingTop='xxxs'>
                          <Highlighter
                            autoEscape
                            highlightStyle={{
                              color: theme.colors.black,
                              backgroundColor: theme.colors.yellowBright,
                            }}
                            searchWords={filterVal.split(' ') || ''}
                            textToHighlight={getMessageBody(messageBody)}
                          />
                        </Text>
                        {messageContent}
                      </Box>
                    ) : (
                      <Text
                        variant='bodySecondary'
                        color='textSecondary'
                        numberOfLines={1}
                        marginRight='l'
                        paddingTop='xxxs'>
                        <MessageParts
                          value={messageBody || ''}
                          configs={[triggersConfigPlain.mention]}
                        />
                      </Text>
                    )}
                  </Box>

                  <Box marginLeft='xxs'>
                    <Text variant='metadataSecondary' color='grey04'>
                      {t('timeAgoChatList', {
                        val:
                          recentActivityAt > new Date()
                            ? new Date()
                            : recentActivityAt,
                      })}
                    </Text>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}
        {collapsed && (
          <Box flexDirection='row' flex={1} height={48}>
            <Avatar
              id={chatId}
              avatar={avatar ? avatar : getChatAvatar(chat)}
              label={name ? name : getChatAvatarLabel(chat)}
              skipCache={true}
              isMultiple={isGroupChat}
              disableAvatarPress
              isSenderFriendRequest={isSenderFriendRequest && !isGroupChat}
            />
            {unreadCount > 0 && (
              <Box
                width={unreadCount > 9 ? 21 : 16}
                height={16}
                backgroundColor={unreadCount > 0 ? 'greenSecondary' : 'grey03'}
                borderRadius='xs'
                position='absolute'
                right={0}
                top={0}>
                <Text
                  variant='metadataSecondary'
                  fontWeight='500'
                  color='white'
                  textAlign='center'>
                  {unreadCount > 99 ? 99 : unreadCount}
                </Text>
              </Box>
            )}
            {isHovered && (
              <Box
                bg='grey05'
                borderRadius='xxs'
                width='70px'
                height='20px'
                style={{ marginLeft: '-10px', marginBottom: '-8px' }}
                position='absolute'
                bottom={0}
                left={0}
                alignItems='center'
                justifyContent='center'
                px='xxs'
                mx='xxs'>
                <Text
                  variant='metadataSecondary'
                  fontWeight='500'
                  color='grey01'
                  numberOfLines={1}>
                  {name ? name : getChatAvatarLabel(chat) || ''}
                </Text>
              </Box>
            )}
          </Box>
        )}
      </TouchableOpacity>
    </Box>
  );
};

const styles = StyleSheet.create({
  chatCard: {
    flexDirection: 'row',
    ...Platform.select({
      web: {
        paddingVertical: 10,
        paddingHorizontal: theme.spacing.m,
      },
      default: {
        paddingTop: theme.spacing.m,
        paddingBottom: theme.spacing.xs,
        paddingHorizontal: theme.spacing.m,
      },
    }),
  },
});

export default ChatCard;

import {
  createMaterialTopTabNavigator,
  MaterialTopTabBarProps,
} from '@react-navigation/material-top-tabs';
import debounce from 'lodash.debounce';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { Alert } from '@components/Alert/index.web';
import CustomTabBar from '@components/CustomTabBar/CustomTabBar';
import { Box } from '@components/Restyle';
import { TabNavigatorSwipeSpacer } from '@components/shared/TabNavigatorSwipeSpacer';
import {
  ChatDrawerScreen,
  useWebDrawer,
} from '@components/Web/Drawer/WebDrawerContext';
import { PopupProjectButtonWeb } from '@components/Web/PopupProjectButton.web';
import { PopupTaskButtonWeb } from '@components/Web/PopupTaskButton.web';
import {
  Document,
  DocumentGroup,
  Project,
  SearchableResult,
  Task,
  TaskGroup,
  useCreateChatMutation,
  User,
  useSearchLazyQuery,
} from '@graphql/generated';
import useActiveChat from '@hooks/useActiveChat';
import useGlobalSearch, { SearchLocation } from '@hooks/useGlobalSearch';
import useMe from '@hooks/useMe';
import { usePreviewDocuments } from '@hooks/usePreviewDocuments';
import useTabFocus from '@hooks/useTabFocus';
import { useToggleCompleteTask } from '@hooks/useToggleCompleteTask';
import { ThemedNavigationContainer } from '@navigation/ThemedNavigationContainer.web';
import { useAppNavigation } from '@navigation/useAppNavigation';
import { Search, SearchResultType } from '@root/types';
import { ImagesPreview } from '@screens/Chats/ImagesPreview.web';
import ContactsSearch from '@screens/Global/ContactsSearch.web';
import FilesSearch from '@screens/Global/FilesSearch.web';
import ProjectsSearch from '@screens/Global/ProjectsSearch.web';
import TasksSearch from '@screens/Global/TasksSearch.web';
import theme from '@themes/theme';
import { openInNewTab } from '@utils/fileUtils';
import {
  isContactSearchResult,
  isDocumentSearchResult,
  isMessageSearchResult,
  isProjectSearchResult,
  isTaskSearchResult,
} from '@utils/typeGuards';

const TabNavigationContainer = ({ children }: { children: ReactNode }) => (
  <ThemedNavigationContainer independent>{children}</ThemedNavigationContainer>
);
const GlobalSearch: React.FC<{
  visible?: boolean;
  doAfterPressItem?: () => void;
}> = ({ visible = true, doAfterPressItem }) => {
  // this param visible is just for fixing the bug, search box lose focus after entering the first character,
  // this bug is because Tab.Navigator appear make input-box lose focus, so I make it always there -- April
  // https://tasktag.atlassian.net/browse/TA-2995
  const { t } = useTranslation('models');

  const [archivesOnly, setArchivesOnly] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string>(
    t('projects.taskList.allStatus')
  );

  const offsetPopButton: Partial<any> = {
    position: 'left',
    offset: [0, 4],
  };

  const DEBOUNCE = 500;
  const appNavigation = useAppNavigation();
  const Tab = createMaterialTopTabNavigator();
  const { tabFocus, setTabFocus } = useTabFocus();
  const [navigationSearchTabs, setNavigationSearchTabs] = useState();
  const { search, setSearch, location, setLocation, setIsSearchFocus } =
    useGlobalSearch();

  const [swiping, setSwiping] = useState(false);
  const { toggleCompleteTask } = useToggleCompleteTask();
  const { me } = useMe();

  const { setActiveMessageCursor, setIsOpenChat } = useActiveChat();
  const { chatExpanded, chatListOpen, setChatListOpen, setShouldChatListOpen } =
    useWebDrawer();

  const { setPreviewDocuments, setActiveIndex } = usePreviewDocuments();
  const [isImageCarouselVisible, setImageCarouselVisible] = useState(false);
  const [isMyUploads, setIsMyUploads] = useState(false);
  const [filesFilter, setFilesFilter] = useState('All');

  const [createChat] = useCreateChatMutation();
  const openChat = (item: User) => {
    const chatName = [item.firstName, item.lastName]
      .filter((item) => !item)
      .join(' ');
    createChat({
      variables: {
        attributes: {
          name: chatName ?? item.name,
          userIds: [item.id],
        },
      },
      onCompleted: (data) => {
        const { createChat: createChatData } = data;
        if (!chatExpanded && chatListOpen) {
          setShouldChatListOpen(true);
          setChatListOpen(false);
        }
        setIsOpenChat(true);
        setActiveMessageCursor && setActiveMessageCursor(undefined);
        appNavigation.navigateToChatDrawer({
          screen: ChatDrawerScreen.details,
          activeChat: createChatData,
        });
      },
      onError: (error) => Alert.alert(t('shared:error'), error.message),
    });
  };

  const [globalSearch, { data, loading }] = useSearchLazyQuery();
  const searchData = (data?.search as Search<SearchableResult>[]) || [];
  const initialResults = {
    messages: [],
    tasks: [],
    documents: [],
    projects: [],
    contacts: [],
  };

  const results =
    searchData.reduce<SearchResultType>((previous, result) => {
      if (isMessageSearchResult(result))
        return { ...previous, messages: [...previous.messages, result] };
      if (isProjectSearchResult(result))
        return { ...previous, projects: [...previous.projects, result] };
      if (isTaskSearchResult(result))
        return { ...previous, tasks: [...previous.tasks, result] };
      if (isDocumentSearchResult(result))
        return { ...previous, documents: [...previous.documents, result] };
      if (isContactSearchResult(result))
        return { ...previous, contacts: [...previous.contacts, result] };

      return previous;
    }, initialResults) || initialResults;

  const tabIsLoading = (tab: SearchLocation): boolean => {
    return (
      (location.includes(tab) && loading) ||
      (swiping && !location.includes(tab))
    );
  };

  const getTaskDueGroup = () => {
    switch (selectedStatus) {
      case t('projects.taskList.section_UPCOMING'):
        return TaskGroup.Upcoming;
      case t('projects.taskList.section_OVERDUE'):
        return TaskGroup.Overdue;
      case t('projects.taskList.section_TODAY'):
        return TaskGroup.Today;
    }
    return undefined;
  };

  const getFilesFilter = () => {
    switch (filesFilter) {
      case 'Media':
        return DocumentGroup.Media;
      case 'Files':
        return DocumentGroup.File;
    }
    return undefined;
  };

  const globalSearchCall = () => {
    return globalSearch({
      variables: {
        term: search,
        size: 30,
        includeMessages: false,
        includeChats: false,
        includeContacts: tabFocus === 'Contacts',
        includeDocuments: tabFocus === 'Files',
        includeProjects: !tabFocus || tabFocus === 'Projects',
        includeTasks: tabFocus === 'Tasks',
        archiveProjectsOnly: archivesOnly,
        taskDueGroup: getTaskDueGroup(),
        completedTasksOnly:
          selectedStatus === t('projects.taskList.section_COMPLETED'),
        includeCompletedTask:
          selectedStatus === t('projects.taskList.section_COMPLETED') ||
          selectedStatus === t('projects.taskList.allStatus'),
        documentOwnerIds:
          tabFocus === 'Files' && isMyUploads && me?.id ? [me?.id] : undefined,
        documentGroup: getFilesFilter(),
      },
    });
  };

  const debouncedGlobalSearch = useCallback(
    debounce(globalSearchCall, DEBOUNCE),
    [search, archivesOnly, selectedStatus, filesFilter, isMyUploads]
  );

  useEffect(() => {
    debouncedGlobalSearch();
    return () => debouncedGlobalSearch.cancel();
  }, [search, archivesOnly, selectedStatus, filesFilter, isMyUploads]);

  useEffect(() => {
    globalSearchCall();
  }, [location]);

  useEffect(() => {
    if (tabFocus === '') {
      setTabFocus('Projects');
    } else if (tabFocus && navigationSearchTabs && tabFocus !== 'Projects') {
      navigationSearchTabs.jumpTo(tabFocus);
    }
  }, [tabFocus]);

  const renderTabBar = (props: MaterialTopTabBarProps) => {
    return (
      <Box px='l'>
        <Box
          flexDirection='row'
          alignItems='center'
          borderBottomWidth={1}
          borderBottomColor='grey02'>
          <CustomTabBar
            {...props}
            initialRouteName={tabFocus}
            spacing={theme.spacing.l}
            contentContainerStyle={styles.contentContainerStyle}
          />
        </Box>
        <Box marginBottom='xs' />
      </Box>
    );
  };

  const getGlobalSearch = () => {
    return (
      <Box
        flex={1}
        style={{ opacity: search ? 1 : 0 }}
        pointerEvents={search ? 'auto' : 'none'}>
        <TabNavigationContainer>
          <Tab.Navigator
            screenOptions={{
              swipeEnabled: false,
            }}
            sceneContainerStyle={styles.sceneContainerStyle}
            tabBar={renderTabBar}
            initialRouteName={tabFocus}
            backBehavior='none'
            screenListeners={{
              state: (e) => {
                const tabLocation =
                  e?.data?.state.routeNames[e.data.state.index];
                if (!tabLocation) return;
                setLocation(tabLocation);
                setTabFocus(tabLocation);
              },
              swipeStart: () => setSwiping(true),
              swipeEnd: () => setSwiping(false),
            }}>
            {!visible && (
              <Tab.Screen
                name='Projects'
                options={{
                  tabBarLabel: 'Projects',
                }}
                initialParams={{ tabBarLabel: 'Projects' }}
                children={() => {
                  return <Box />;
                }}
              />
            )}
            {visible && (
              <Tab.Screen
                name='Projects'
                options={{
                  tabBarLabel: 'Projects',
                }}
                initialParams={{ tabBarLabel: 'Projects' }}
                children={({ navigation }) => {
                  !navigationSearchTabs && setNavigationSearchTabs(navigation);
                  return (
                    <SearchTabContainer>
                      <ProjectsSearch
                        search={search}
                        filterPart={
                          <PopupProjectButtonWeb
                            title={
                              archivesOnly
                                ? t('projects.buttons.archivedProjects')
                                : t('projects.buttons.activeProjects')
                            }
                            boxProps={offsetPopButton}
                            onPressArchiveProjects={() => {
                              setArchivesOnly(true);
                            }}
                            onPressActiveProjects={() => {
                              setArchivesOnly(false);
                            }}
                          />
                        }
                        data={results.projects}
                        loading={tabIsLoading('Projects')}
                        onPress={(project: Project) => {
                          setIsSearchFocus(false);
                          doAfterPressItem && doAfterPressItem();
                          setSearch('');
                          setTabFocus('Projects');
                          appNavigation.navigateToProject(project);
                        }}
                      />
                    </SearchTabContainer>
                  );
                }}
              />
            )}
            {visible && (
              <Tab.Screen
                name='Tasks'
                options={{
                  tabBarLabel: 'Tasks',
                }}
                initialParams={{ tabBarLabel: 'Tasks' }}
                children={({ navigation }) => {
                  !navigationSearchTabs && setNavigationSearchTabs(navigation);
                  return (
                    <SearchTabContainer>
                      <TasksSearch
                        filterPart={
                          <PopupTaskButtonWeb
                            title={selectedStatus}
                            boxProps={offsetPopButton}
                            selectedStatus={selectedStatus}
                            onSelectStatus={(statuses) => {
                              setSelectedStatus(statuses);
                            }}
                          />
                        }
                        search={search}
                        data={results.tasks}
                        toggleCompleteTask={toggleCompleteTask}
                        loading={tabIsLoading('Tasks')}
                        onPress={(task: Task) => {
                          setIsSearchFocus(false);
                          doAfterPressItem && doAfterPressItem();
                          setSearch('');
                          setTabFocus('Tasks');
                          appNavigation.navigateToTask(task);
                        }}
                      />
                    </SearchTabContainer>
                  );
                }}
              />
            )}
            {visible && (
              <Tab.Screen
                name='Files'
                options={{
                  tabBarLabel: 'Files',
                }}
                initialParams={{ tabBarLabel: 'Files' }}
                children={({ navigation }) => {
                  !navigationSearchTabs && setNavigationSearchTabs(navigation);
                  return (
                    <SearchTabContainer>
                      <FilesSearch
                        search={search}
                        data={results.documents}
                        loading={tabIsLoading('Files')}
                        isMyUploads={isMyUploads}
                        setIsMyUploads={setIsMyUploads}
                        filesFilter={filesFilter}
                        setFilesFilter={setFilesFilter}
                        onPress={(document: Document) => {
                          if (
                            document.contentType?.startsWith('image/') ||
                            document.isImage
                          ) {
                            const documents = results.documents.filter(
                              (item) =>
                                item.record?.contentType?.startsWith(
                                  'image/'
                                ) || item.record?.isImage
                            );
                            setPreviewDocuments(
                              documents.map((item) => item?.record)
                            );
                            setActiveIndex(
                              documents.findIndex(
                                (i) => i.record.id === document.id
                              )
                            );
                            return setImageCarouselVisible(true);
                          } else {
                            return openInNewTab(document);
                          }
                        }}
                      />
                    </SearchTabContainer>
                  );
                }}
              />
            )}
            {visible && (
              <Tab.Screen
                name='Contacts'
                options={{
                  tabBarLabel: 'Contacts',
                }}
                initialParams={{ tabBarLabel: 'Contacts' }}
                children={({ navigation }) => {
                  !navigationSearchTabs && setNavigationSearchTabs(navigation);
                  return (
                    <SearchTabContainer>
                      <ContactsSearch
                        search={search}
                        contacts={results.contacts}
                        loading={tabIsLoading('Contacts')}
                        onPress={(contact: User) => {
                          setIsSearchFocus(false);
                          doAfterPressItem && doAfterPressItem();
                          setSearch('');
                          setTabFocus('Contacts');
                          appNavigation.navigateToContact(contact);
                        }}
                        onMessagePress={(contact: User) => {
                          setIsSearchFocus(false);
                          doAfterPressItem && doAfterPressItem();
                          setSearch('');
                          setTabFocus('Contacts');
                          openChat(contact);
                        }}
                      />
                    </SearchTabContainer>
                  );
                }}
              />
            )}
          </Tab.Navigator>
        </TabNavigationContainer>
        <TabNavigatorSwipeSpacer />
        {isImageCarouselVisible && (
          <ImagesPreview
            onDismiss={() => setImageCarouselVisible(false)}
            isGlobalSearch
          />
        )}
      </Box>
    );
  };

  return getGlobalSearch();
};

const SearchTabContainer = ({ children }: { children: ReactNode }) => (
  <Box flex={1}>{children}</Box>
);

const styles = StyleSheet.create({
  contentContainerStyle: {
    width: '100%',
    justifyContent: 'flex-start',
  },
  sceneContainerStyle: {
    backgroundColor: 'transparent',
  },
});

export default GlobalSearch;

import { useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ActivityList from '@components/ActivityList/ActivityList';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import {
  GetTaskDocument,
  Task,
  useGetTaskQuery,
  useMarkAuditsReadMutation,
} from '@graphql/generated';
import { useListTaskActivityFromQuery } from '@hooks/useListTaskActivityFromQuery';
import { TasksStackScreenProps } from '@navigation/tasks/tasks-stack';
const PER_PAGE_COUNT = 10;

export interface TaskActivityLogProps {
  taskID?: Task['id'];
}
export const TaskActivityLog: React.FC<TaskActivityLogProps> = ({ taskID }) => {
  const route = useRoute<TasksStackScreenProps<'task-members'>['route']>();

  const { taskId: routeTaskId = '' } = route.params || {};
  const taskId = routeTaskId || taskID;
  const [loadingMore, setLoadingMore] = useState(false);
  const { t } = useTranslation();

  const {
    activity: allTaskActivity,
    refetch: refetchTasks,
    refreshing: refreshingTasks,
    pageInfo: pageInfo,
    setRefreshing: setRefreshingTasks,
    fetchMoreFromCursor: fetchMoreTasksFromCursor,
    loading,
  } = useListTaskActivityFromQuery({
    taskId,
    first: PER_PAGE_COUNT,
    onCompleted: () => {
      setLoadingMore(false);
    },
  });
  const onLoadMore = () => {
    setLoadingMore(true);
    fetchMoreTasksFromCursor();
  };

  const { data: taskInfo } = useGetTaskQuery({
    variables: {
      id: taskId || '',
    },
    skip: !taskId,
  });

  const [markAuditsRead] = useMarkAuditsReadMutation({
    refetchQueries: [{ query: GetTaskDocument, variables: { id: taskId } }],
  });

  useEffect(() => {
    if ((taskInfo?.getTask.unreadAudits?.length ?? 0) > 0) {
      markAuditsRead({
        variables: {
          attributes: {
            auditIds: taskInfo?.getTask.unreadAudits?.map((a) => a.id),
          },
        },
      });
    }
  }, []);

  if (loading) {
    return <ActivityIndicatorLoading />;
  }

  const renderEmptyActiveComponent = () => {
    return (
      <Box
        alignItems='center'
        alignSelf='center'
        alignContent='center'
        justifyContent='center'>
        <Text color='grey04' textAlign='center'>
          {t('NoRecordFound')}
        </Text>
      </Box>
    );
  };
  return (
    <Box flex={1} backgroundColor='white'>
      <ActivityList
        activityType='Task'
        refreshing={refreshingTasks}
        // must convert id's to number to sort correctly
        audits={allTaskActivity || []}
        taskId={taskId}
        onRefresh={() => {
          setRefreshingTasks(true);
          refetchTasks();
        }}
        fetchMore={fetchMoreTasksFromCursor}
        loadingMore={loadingMore}
        onEndReached={onLoadMore}
        renderEmptyActiveComponent={renderEmptyActiveComponent}
        listFooterComponent={() => (
          <>
            {pageInfo?.hasNextPage ? (
              <Box mb='listFooter'>
                {loadingMore && <ActivityIndicatorLoading />}
              </Box>
            ) : (
              <Box mb='l'></Box>
            )}
          </>
        )}
      />
    </Box>
  );
};

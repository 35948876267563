import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs';
import React, { useEffect, useRef, useState } from 'react';
import {
  Animated,
  Dimensions,
  LayoutChangeEvent,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';

import { AvatarProps } from '@components/Avatar/Avatar';
import MemberListSummary from '@components/MemberListSummary/MemberListSummary';
import { Box, ShadowBox, Text } from '@components/Restyle/index';
import Icon from '@components/shared/Icon/Icon';
import Tag from '@components/shared/Tags/Tag';
import { ProjectMember } from '@graphql/generated';
import documentIcons from '@themes/documentIcons';
import Images from '@themes/images';
import theme from '@themes/theme';

export type CustomerTabbarProps = {
  spacing?: number;
  panelKey: string;
  initialRouteName?: string;
  contentContainerStyle?: StyleProp<ViewStyle>;
  isProjectTabs?: boolean;
  isProfileTabs?: boolean;
  nonMember?: boolean;
  members?: ProjectMember[] | undefined;
  navigateToProjectMembers?: () => void;
};

type IProps = MaterialTopTabBarProps & CustomerTabbarProps;

const CustomTabPanel = ({
  state,
  descriptors,
  navigation,
  spacing = theme.spacing.m,
  initialRouteName,
  contentContainerStyle,
  isProjectTabs = false,
  isProfileTabs = false,
  nonMember = false,
  panelKey,
  members,
}: IProps): JSX.Element => {
  const scaleX_value = useRef(new Animated.Value(0)).current;
  const translateX_value = useRef(new Animated.Value(0)).current;

  type Position = {
    index: number;
    width: number;
    x: number;
  };

  const [positions, setPositions] = useState<Position[]>([]);

  // get the label widths on mount
  const onLayout = (event: LayoutChangeEvent, index: number, label: string) => {
    const { width, x } = event.nativeEvent.layout;

    const calcX = x + width / 2;

    setPositions((pos) => [...pos, { index: index, width: width, x: calcX }]);

    if (initialRouteName && initialRouteName === label) {
      scaleX_value.setValue(width);
      translateX_value.setValue(calcX);
    }

    if (!initialRouteName && index === 0) {
      scaleX_value.setValue(width);
      translateX_value.setValue(calcX);
    }
  };

  useEffect(() => {
    const newPos = positions.findIndex((i) => i.index === state.index);
    positions.length > 0 && animate(newPos);
  }, [state, spacing]);

  const getNewPosX = (position: Position) => {
    return position.x;
  };

  const animate = (newPos: number) => {
    Animated.parallel([
      Animated.timing(translateX_value, {
        toValue: getNewPosX(positions[newPos]),
        duration: 300,
        useNativeDriver: true,
      }),
      Animated.timing(scaleX_value, {
        toValue: positions[newPos].width,
        duration: 300,
        useNativeDriver: true,
      }),
    ]).start();
  };

  // basic labels as suggested by react navigation
  const labels = state.routes.map((route, index) => {
    const { options } = descriptors[route.key];
    const label = route.params?.tabBarLabel || options.tabBarLabel;
    const isFocused = state.index === index;

    const onPress = () => {
      const event = navigation.emit({
        type: 'tabPress',
        target: route.key,
        canPreventDefault: true,
      });

      if (!isFocused && !event.defaultPrevented) {
        // The `merge: true` option makes sure that the params inside the tab screen are preserved
        // eslint-disable-next-line
        // @ts-ignore
        navigation.navigate({ name: route.name, merge: true });
      }
    };

    const getIcon = () => {
      let iconName: keyof typeof Images | keyof typeof documentIcons = 'Hash';
      switch (route.name) {
        case 'project-details':
          iconName = 'Folder';
          break;
        case 'tasks':
          iconName = 'Hash';
          break;
        case 'files':
          iconName = 'File';
          break;
        case 'activity-log':
          iconName = 'Zap';
          break;
        case 'members':
          iconName = 'Users';
          break;
        case 'my-account':
          iconName = 'UserCircle';
          break;
        case 'subscription-plans':
          iconName = 'Zap';
          break;
        case 'team-plan':
          iconName = 'Star';
          break;
      }
      return iconName;
    };

    const userAvatars = (members: ProjectMember[]): AvatarProps[] => {
      return (
        members?.map((u) => {
          const { user } = u;
          const { name, id, avatar } = user;
          return {
            id: id,
            label: name,
            avatar: avatar,
            size: 'memberList',
          };
        }) || []
      );
    };

    const tabWidth = Dimensions.get('window').width >= 1920 ? 190 : 145;

    if (route.name === 'team-plan') {
      const bgColor = isFocused
        ? theme.colors.greenSecondaryMild
        : theme.colors.white;
      const borderColor = isFocused
        ? theme.colors.greenSecondaryMild
        : theme.colors.purple;
      const bgColorIcon = isFocused
        ? theme.colors.greenSecondary
        : theme.colors.purple;
      const bgColorText = isFocused
        ? theme.colors.greenSecondary
        : theme.colors.textPrimary;
      return (
        <Box flex={1} flexDirection='row' justifyContent='flex-end'>
          <ShadowBox
            key={route.key}
            width={130}
            borderWidth={1}
            borderRadius='xs'
            justifyContent='flex-start'
            style={[
              {
                marginRight: theme.spacing.m,
                backgroundColor: nonMember ? theme.colors.white : bgColor,
                borderColor: nonMember ? theme.colors.grey02 : borderColor,
              },
            ]}>
            <Box
              accessibilityLabel={label}
              alignItems='center'
              justifyContent='flex-start'
              onLayout={(event) => onLayout(event, index, label)}>
              <TouchableOpacity
                accessibilityRole='button'
                accessibilityState={isFocused ? { selected: true } : {}}
                style={{
                  width: 130,
                }}
                accessibilityLabel={options.tabBarAccessibilityLabel}
                onPress={onPress}
                disabled={nonMember}>
                <Box
                  padding='xs'
                  flexDirection='row'
                  alignItems='center'
                  justifyContent='flex-start'>
                  <Box
                    alignItems='center'
                    justifyContent='center'
                    marginRight='xs'
                    style={{
                      borderRadius: 5,
                      padding: 6,
                      backgroundColor: nonMember
                        ? theme.colors.grey03
                        : bgColorIcon,
                    }}>
                    <Icon name={getIcon()} variant='m' color='white' />
                  </Box>
                  <Box
                    flex={1}
                    flexDirection='row'
                    alignItems='center'
                    justifyContent='flex-start'>
                    <Text
                      variant='bodyTaskName'
                      textAlign='center'
                      style={{
                        color: nonMember ? theme.colors.grey03 : bgColorText,
                      }}>
                      {label}
                    </Text>
                  </Box>
                </Box>
              </TouchableOpacity>
            </Box>
          </ShadowBox>
        </Box>
      );
    }

    if (isProjectTabs || isProfileTabs) {
      const bgColor = isFocused
        ? theme.colors.greenSecondaryMild
        : theme.colors.white;
      const borderColor = isFocused
        ? theme.colors.greenSecondaryMild
        : theme.colors.grey02;
      const bgColorIcon = isFocused
        ? theme.colors.greenSecondary
        : theme.colors.textPrimary;
      const bgColorText = isFocused
        ? theme.colors.greenSecondary
        : theme.colors.textPrimary;
      let xTabWidth =
        route.name === 'members' && members ? tabWidth + 80 : tabWidth;
      if (route.name === 'subscription-plans') xTabWidth = tabWidth + 90;
      return (
        <ShadowBox
          key={route.key}
          width={xTabWidth}
          borderWidth={1}
          borderRadius='xs'
          justifyContent='flex-start'
          style={[
            {
              marginRight:
                Dimensions.get('window').width <= 1280
                  ? theme.spacing.xs
                  : spacing,
              backgroundColor: nonMember ? theme.colors.white : bgColor,
              borderColor: nonMember ? theme.colors.grey02 : borderColor,
            },
          ]}>
          <Box
            key={route.key}
            accessibilityLabel={label}
            alignItems='center'
            justifyContent='flex-start'
            onLayout={(event) => onLayout(event, index, label)}>
            <TouchableOpacity
              accessibilityRole='button'
              accessibilityState={isFocused ? { selected: true } : {}}
              style={{
                width: xTabWidth,
              }}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              onPress={onPress}
              disabled={nonMember}>
              <Box
                padding='xs'
                flexDirection='row'
                alignItems='center'
                justifyContent='flex-start'>
                <Box
                  alignItems='center'
                  justifyContent='center'
                  marginRight='xs'
                  style={{
                    borderRadius: 5,
                    padding: 6,
                    backgroundColor: nonMember
                      ? theme.colors.grey03
                      : bgColorIcon,
                  }}>
                  <Icon name={getIcon()} variant='m' color='white' />
                </Box>
                <Box
                  flex={1}
                  flexDirection='row'
                  alignItems='center'
                  justifyContent='flex-start'>
                  <Text
                    variant='bodyTaskName'
                    textAlign='center'
                    style={{
                      color: nonMember ? theme.colors.grey03 : bgColorText,
                    }}>
                    {label}
                  </Text>
                </Box>
                {route.name === 'subscription-plans' && (
                  <Tag
                    label='Coming Soon'
                    labelVariant='metadata'
                    variant='secondary'
                    padding='xxs'
                    borderRadius='xxs'
                  />
                )}
                {members && route.name === 'members' && (
                  <MemberListSummary
                    avatars={userAvatars(members)}
                    onPress={nonMember ? undefined : onPress}
                    maxAvatars={3}
                  />
                )}
              </Box>
            </TouchableOpacity>
          </Box>
        </ShadowBox>
      );
    }

    return (
      <Box
        key={route.key}
        alignItems='center'
        justifyContent='center'
        style={{ marginRight: spacing }}
        onLayout={(event) => onLayout(event, index, label)}>
        <TouchableOpacity
          accessibilityRole='button'
          accessibilityState={isFocused ? { selected: true } : {}}
          style={
            isFocused
              ? {
                  backgroundColor: '#DCF2EC',
                  width: 120,
                  borderRadius: 4,
                }
              : {
                  width: 120,
                }
          }
          accessibilityLabel={options.tabBarAccessibilityLabel}
          onPress={onPress}>
          <Box paddingVertical='xs'>
            <Text
              variant='labelAssignedTask'
              textAlign='center'
              color={isFocused ? 'greenSecondary' : 'grey04'}>
              {label}
            </Text>
          </Box>
        </TouchableOpacity>
      </Box>
    );
  });

  return (
    <Box flex={1} key={panelKey}>
      <Animated.View style={{ ...styles.container, ...contentContainerStyle }}>
        {labels}
        <Animated.View
          style={[
            {
              transform: [
                { translateX: translateX_value },
                { scaleX: scaleX_value },
              ],
            },
          ]}
        />
      </Animated.View>
    </Box>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  indicator: {
    backgroundColor: theme.colors.greenSecondary,
    height: 2,
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    width: 1,
  },
});

export default CustomTabPanel;

import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Platform, TouchableOpacity } from 'react-native';

import { AddMemberType } from '@components/Invite/SelectMembersList';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Button from '@components/shared/Button/Button';
import { MemberRole, MemberType } from '@components/User/MemberRole';
import {
  User,
  TeamRole,
  TaskMemberRole,
  ProjectMemberRole,
} from '@graphql/generated';

export type UserAndRole = {
  user: AddMemberType;
  role: TeamRole;
};
interface EditNewMemberRolesProps<Roles> {
  updateMemberRole: (id: User['id'], role: Roles) => void;
  onSubmit: () => void;
  isSubmitting: boolean;
  isTaskAssignee: boolean;
  usersAndRoles?: MemberType<Roles>[];
  roles: string[];
  currentRole?: string | null;
}

export const EditNewMemberRoles = <Roles,>({
  updateMemberRole,
  onSubmit,
  isSubmitting,
  isTaskAssignee,
  usersAndRoles,
  roles,
  currentRole,
}: EditNewMemberRolesProps<Roles>) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  // eslint-disable-next-line no-nested-ternary
  const memberRole = isTaskAssignee
    ? [TaskMemberRole.Viewer]
    : currentRole == ProjectMemberRole.Editor
    ? [ProjectMemberRole.Editor, ProjectMemberRole.Viewer]
    : roles;
  return (
    <Box flex={1} backgroundColor='white'>
      <FlatList
        data={usersAndRoles?.filter((item) => item.role !== 'OWNER')}
        renderItem={({ item }) => {
          return (
            <Box marginLeft='m' marginRight='l'>
              <MemberRole<Roles>
                roles={memberRole}
                member={item}
                updateRole={(role: Roles) => {
                  updateMemberRole(item.user.id, role);
                }}
              />
            </Box>
          );
        }}
        keyExtractor={(item) => item.user.id}
        ItemSeparatorComponent={() => <Box marginTop='m' />}
        ListFooterComponent={() => <Box marginBottom='listFooter' />}
        ListHeaderComponent={() => <Box marginTop='m' />}
      />

      {Platform.OS === 'web' ? (
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='flex-end'
          mr='l'
          marginVertical='m'>
          <TouchableOpacity
            onPress={() => navigation.goBack()}
            disabled={isSubmitting}>
            <Text>{t('shared:back')}</Text>
          </TouchableOpacity>
          <Button
            variant='primary'
            disabled={isSubmitting}
            onPress={onSubmit}
            marginLeft='l'>
            {t('shared:addMembers')}
          </Button>
        </Box>
      ) : (
        <Button
          variant='primary'
          disabled={isSubmitting}
          onPress={onSubmit}
          float='bottom-inset'>
          {isSubmitting ? <ActivityIndicatorLoading /> : t('shared:confirm')}
        </Button>
      )}
    </Box>
  );
};

import { useRoute } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { WebModal } from '@components/Modals/WebModal.web';
import { Box, ShadowBox, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Icon from '@components/shared/Icon/Icon';
import CopyTaskDetails from '@components/Tasks/CopyTaskDetails';
import { EditTask } from '@components/Tasks/EditTask';
import TaskFormStack, { FormValues } from '@components/Tasks/TaskFormStack';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import {
  Project,
  Task,
  TaskPriority,
  TaskStatus,
  useGetTaskQuery,
  User,
} from '@graphql/generated';
import { clearModalStyle } from '@navigation/helpers';
import { ProjectDetailTabsScreenProps } from '@navigation/projects/project-detail-tabs.web';
import { TaskDetailTabs } from '@navigation/tasks/task-detail-tabs.web';
import { localDateFromUTCDate } from '@utils/formatters/date';

export type TaskPanelParamsList = {
  task: {
    taskId?: Task['id'];
  };
  create: undefined;
  'task-edit': { taskId: Task['id'] };
  'task-copy-modal': { taskId?: Task['id'] };
  'task-detail-edit-dates': {
    taskId?: Task['id'];
    fieldName: 'startDate' | 'dueDate';
  };
};

type TaskPanelProps = {
  idtask: string;
  isFromFullScreen?: boolean;
  isMaximize: boolean;
  onMaximize: () => void;
  onClose: () => void;
  onNavigation: (task: Task) => void;
  onPressCreator?: (task: Task) => void;
  onPressMember?: (item: User) => void;
  onPressProject?: (item: Project) => void;
};

const Stack = createStackNavigator<TaskPanelParamsList>();

export const TaskPanel: React.FC<TaskPanelProps> = ({
  idtask,
  isMaximize,
  onMaximize,
  onClose,
  onNavigation,
  onPressCreator,
  onPressMember,
  onPressProject,
  isFromFullScreen = false,
}) => {
  const { t } = useTranslation();
  let isFromMyTask = false;
  let taskId = idtask;
  if (!isFromFullScreen) {
    const {
      params: { taskId: taskIdx, isFromMyTask: isFromMyTaskx } = {
        taskId: '',
        isFromMyTask: false,
      },
    } = useRoute<ProjectDetailTabsScreenProps<'tasks'>['route']>() || {};
    taskId = taskIdx ?? '';
    isFromMyTask = isFromMyTaskx ?? false;
  }

  const { idProjectOfTaskDetailOpen } = useWebDrawer();

  const { data: { getTask: task } = {} as { getTask: Task }, loading } =
    taskId != ''
      ? useGetTaskQuery({
          variables: {
            id: taskId ?? '',
          },
          skip: !taskId,
          fetchPolicy: 'cache-and-network',
        })
      : { loading: false };

  const editForm = (editStr: string) => {
    const {
      startDate: initialStartDate,
      dueDate: initialDueDate,
      dependencies,
      description: initialDescription,
      name: initialName,
    } = task as Task;

    const startDate =
      initialStartDate && localDateFromUTCDate(initialStartDate);
    const dueDate = initialDueDate && localDateFromUTCDate(initialDueDate);

    const dependencyIds = dependencies?.map((d) => d.id) || [];

    const description = initialDescription || '';
    const name = initialName || '';

    const initialValues: FormValues = {
      ...(!!task && task),
      ...{ startDate, dueDate, name, description },
      dependencyIds,
      projectId: task?.projectId ?? '0',
      users: (task?.members ?? []).map((m) => {
        return {
          id: m.user.id,
          avatar: m.user.avatar,
          name: m.user.name,
          firstName: m.user.firstName,
          lastName: m.user.lastName,
          role: m.role,
        };
      }),
      skillIds: task?.skillIds ?? [],
      phaseId: task?.phaseId ?? undefined,
      fixedOrder: task?.fixedOrder ?? false,
      recurring: task?.recurring ?? false,
      priority: task?.priority ?? TaskPriority.Low,
      status: task?.status ?? TaskStatus.Ongoing,
      frequency: task?.frequency ?? undefined,
      completedAt: task?.completedAt ?? null,
    };
    return (
      <TaskFormStack
        taskId={taskId}
        initialValues={initialValues}
        isShortModal={true}
        editField={editStr}
      />
    );
  };

  if (taskId && taskId !== '' && loading) {
    return (
      <Box mt='xs'>
        <ActivityIndicatorLoading />
      </Box>
    );
  }

  if (taskId && taskId !== '' && !task) {
    return (
      <ShadowBox key={taskId} flex={1} p='m' ml='m'>
        <Box flexDirection='row' backgroundColor='white'>
          <Box alignItems='flex-end'>
            <Box flexDirection='row' accessibilityLabel='Close'>
              <Icon name='X' onPress={onClose} />
            </Box>
          </Box>
        </Box>
        <Text>Task not found</Text>
      </ShadowBox>
    );
  }

  const initialValues = {
    name: '',
    description: '',
    projectId: idProjectOfTaskDetailOpen ?? '',
    users: [],
    completedAt: undefined,
    skillIds: [],
    phaseId: undefined,
    dependencyIds: [],
    fixedOrder: false,
    recurring: false,
    priority: TaskPriority.Low,
    status: TaskStatus.Ongoing,
    frequency: undefined,
    startDate: undefined,
    dueDate: undefined,
    checkList: [],
  };

  const page = () => (
    <Stack.Navigator>
      {taskId === '' && (
        <Stack.Screen
          name='task'
          getId={() => taskId}
          options={{
            header: () => (
              <Box flexDirection='row' backgroundColor='white'>
                <Box flex={1}>
                  <Text variant='heading2'>
                    {t('models:tasks.create.title')}
                  </Text>
                </Box>
                <Box alignItems='flex-end'>
                  <Box flexDirection='row' accessibilityLabel='Close'>
                    <Icon name='X' onPress={onClose} />
                  </Box>
                </Box>
              </Box>
            ),
          }}>
          {() => (
            <Box flex={1} backgroundColor='white'>
              <TaskFormStack
                screenName='Create'
                key={false}
                selectedList={[]}
                initialValues={initialValues}
                isShortModal={false}
                isAssignTask={false}
                chatId=''
                taskId=''
                isCopyTask={true}
                openModal={true}
                checkList={[]}
              />
            </Box>
          )}
        </Stack.Screen>
      )}
      {taskId !== '' && (
        <Stack.Screen
          name='task'
          getId={() => taskId}
          options={{ headerShown: false }}>
          {() => (
            <TaskDetailTabs
              isFromMyTask={isFromMyTask}
              task={task}
              onClose={onClose}
              isMaximize={isMaximize}
              onMaximize={onMaximize}
              onPressCreator={onPressCreator}
              onPressMember={onPressMember}
              onPressProject={onPressProject}
            />
          )}
        </Stack.Screen>
      )}
      <Stack.Screen
        name='task-detail-edit-dates'
        options={{ ...clearModalStyle }}>
        {() => editForm('dates')}
      </Stack.Screen>
      <Stack.Screen
        name='task-edit'
        getId={() => taskId}
        options={{
          header: () => (
            <Box flexDirection='row' backgroundColor='white'>
              <Box flex={1}>
                <Text variant='heading2'>{task.name}</Text>
              </Box>
              <Box alignItems='flex-end'>
                <Box flexDirection='row'>
                  <Icon name='X' onPress={onClose} />
                </Box>
              </Box>
            </Box>
          ),
        }}>
        {() => (
          <Box flex={1} backgroundColor='white'>
            <EditTask taskId={taskId} />
          </Box>
        )}
      </Stack.Screen>
      <Stack.Screen
        name='task-copy-modal'
        options={{
          headerShown: false,
          presentation: 'transparentModal',
        }}>
        {({ navigation }) => (
          <WebModal
            visible
            title={t('shared:select')}
            width={504}
            onClose={navigation.goBack}>
            <CopyTaskDetails onComplete={onNavigation} />
          </WebModal>
        )}
      </Stack.Screen>
    </Stack.Navigator>
  );

  return isFromFullScreen ? (
    page()
  ) : (
    <ShadowBox key={taskId} flex={1} p='m' ml='m'>
      {page()}
    </ShadowBox>
  );
};

import { useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';
import * as Clipboard from 'expo-clipboard';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, Share } from 'react-native';

import { Alert } from '@components/Alert';
import {
  ListContactUsersDocument,
  useCreateContactInvitationUrlMutation,
  useGetMeQuery,
  User,
  useUploadContactsMutation,
} from '@graphql/generated';
import { useCrashlytics } from '@hooks/useCrashlytics';
import { getContacts, getDeviceContactsNew } from '@hooks/useDeviceContacts';
import { useAuthContext } from '@src/context/authContext';
import {
  logError,
  LogGraphError,
  LogNetworkError,
} from '@utils/logNonFatalError';

type MeContext = {
  me?: User;
  refetchMe: () => Promise<void>;
  inviteViaPhone: (
    phoneNumber: User['phoneNumber'],
    showInviteeAlert?: User['phoneNumber' | 'name']
  ) => void;
  shareInviteLink: () => void;
  copyInviteLinkToClipboard: () => void;
  isPaid?: boolean;
  setPaid: React.Dispatch<React.SetStateAction<boolean>>;
  loading?: boolean;
};

export const meContext = createContext<MeContext | undefined>(undefined);

export const MeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigation = useNavigation();
  const { setAttributes, setUserId } = useCrashlytics();
  const [isPaid, setPaid] = useState<boolean>(false);

  const { Provider } = meContext;
  const { t } = useTranslation('models');
  const { token } = useAuthContext();
  const { data, loading, refetch } = useGetMeQuery({
    skip: !token,
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      if (error.networkError) {
        const event: LogNetworkError = {
          message: 'Get Me Query Network Failure',
          errorMessage: error.message,
          statusCode:
            'statusCode' in error.networkError
              ? error.networkError.statusCode
              : -1,
        };
        logError(event);
      }
      error.graphQLErrors.forEach((graphError) => {
        const event: LogGraphError = {
          message: 'Get Me Query GraphQL Error',
          errorMessage: graphError.message,
          operationName: 'GetMe',
        };
        logError(event);
      });
    },
  });

  const refetchMe = async () => {
    try {
      await refetch();
    } catch (error) {
      console.error('Error refetching me data:', error);
    }
  };

  const { getMe } = data || {
    getMe: undefined,
  };

  const memoizedMe = useMemo(() => {
    return getMe;
  }, [
    getMe?.id,
    getMe?.name,
    getMe?.skills,
    getMe?.avatar?.url,
    getMe?.contactsSyncedAt,
    getMe?.email,
    getMe?.timeZone,
    getMe?.tutoralizationSetting,
  ]);

  const { inviteUrl, contactsSyncedAt } = memoizedMe || {
    inviteUrl: '',
    contactsSyncedAt: undefined,
  };

  const copyInviteLinkToClipboard = useCallback(async () => {
    await Clipboard.setStringAsync(inviteUrl);
  }, [inviteUrl]);
  const [scrubbedNumber, setScrubbedNumber] = useState<string>('');

  const [createContactInvitationUrl] = useCreateContactInvitationUrlMutation({
    onCompleted: (data) => {
      const { createContactInvitationUrl } = data;
      const isArray = Array.isArray(scrubbedNumber);
      let numbers = '';
      if (isArray) {
        scrubbedNumber.forEach((phoneNumber: string) => {
          numbers += `${phoneNumber},`;
        });
      }

      numbers = isArray ? numbers.slice(0, -1) : scrubbedNumber;

      const message = `${t('invite.message')} ${createContactInvitationUrl}`;
      const operator = Platform.select({ ios: '&', android: '?' });
      Linking.openURL(
        `sms:/open?addresses=${numbers}${operator}body=${message}`
      ).then(() => {
        isArray && navigation.goBack();
      });
    },
  });

  const inviteViaPhone = useCallback(
    (
      phoneNumber: User['phoneNumber'] | [],
      showInviteeAlert?: User['phoneNumber' | 'name']
    ) => {
      setScrubbedNumber(phoneNumber);
      const openMessages = () => {
        createContactInvitationUrl({
          skip: !token,
          variables: {
            attributes: {
              phoneNumber: phoneNumber,
            },
          },
        });
      };

      if (!inviteUrl) return;
      if (showInviteeAlert) {
        Alert.alert(
          t('invite.title'),
          t('invite.alert', { user: showInviteeAlert }),
          [
            { text: 'Cancel' },
            {
              text: 'Invite',

              onPress: () => {
                openMessages();
              },
            },
          ]
        );
      } else {
        openMessages();
      }
    },
    [inviteUrl]
  );

  const shareInviteLink = useCallback(async () => {
    if (inviteUrl) {
      try {
        await Share.share({
          message: inviteUrl,
        });
      } catch (err) {
        alert(err);
      }
    }
  }, [inviteUrl]);

  const [uploadContacts] = useUploadContactsMutation({
    refetchQueries: [{ query: ListContactUsersDocument }],
  });

  useEffect(() => {
    if (
      contactsSyncedAt &&
      dayjs().isAfter(dayjs(contactsSyncedAt).add(1, 'day'))
    ) {
      Platform.OS === 'android' &&
        getContacts(undefined, undefined, false, t, uploadContacts);
      Platform.OS !== 'android' &&
        getDeviceContactsNew(undefined, undefined, false, uploadContacts);
    }
  }, [contactsSyncedAt]);

  useEffect(() => {
    if (!memoizedMe) return;

    const sendCrashlytics = async () => {
      await setUserId(memoizedMe.id);
      await setAttributes({
        email: memoizedMe.email,
      });
    };

    sendCrashlytics();
  }, [memoizedMe]);

  const providerValue = useMemo(() => {
    return {
      me: memoizedMe as User,
      refetchMe,
      inviteViaPhone,
      shareInviteLink,
      copyInviteLinkToClipboard,
      isPaid,
      setPaid,
      loading,
    };
  }, [memoizedMe]);

  return <Provider value={providerValue}>{children}</Provider>;
};

const useMe = (): MeContext => {
  const context = useContext(meContext);
  if (context === undefined) {
    throw new Error('useMe must be used within a Provider');
  }
  return context;
};

export default useMe;

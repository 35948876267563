import React, { createContext, useContext, useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { ChatMessageListItem } from '@components/Chat/ChatMessageList.web';
import { ListChatsQuery } from '@graphql/generated';

export type ActiveChat = ListChatsQuery['listChats'][0];

type ActiveChatContext = {
  isOpenChat: boolean;
  setIsOpenChat: React.Dispatch<React.SetStateAction<boolean>>;
  isEditTagModalOpen: boolean;
  setIsEditTagModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isForwardMessageModalOpen: boolean;
  setIsForwardMessageModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  activeChat?: ActiveChat;
  activeChatId?: string;
  activeMessageCursor?: string;
  setActiveMessageCursor?: React.Dispatch<
    React.SetStateAction<ActiveChatContext['activeMessageCursor']>
  >;
  setActiveChat: React.Dispatch<
    React.SetStateAction<ActiveChatContext['activeChat']>
  >;
  cursorScrollRequested: boolean;
  setCursorScrollRequested: React.Dispatch<
    React.SetStateAction<ActiveChatContext['cursorScrollRequested']>
  >;
  isDeleteMessageModalOpen: boolean;
  setIsDeleteMessageModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  drawerCollapsed: boolean;
  setDrawerCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  displayMessageList?: ChatMessageListItem[];
  setDisplayMessageList: React.Dispatch<
    React.SetStateAction<ChatMessageListItem[]>
  >;
  isScrolling: boolean;
  setIsScrolling: React.Dispatch<React.SetStateAction<boolean>>;
};

const activeChatContext = createContext<ActiveChatContext | undefined>(
  undefined
);

export const ActiveChatProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { Provider } = activeChatContext;
  const [activeChat, setActiveChat] =
    useState<ActiveChatContext['activeChat']>();
  const [activeChatId, setActiveChatId] =
    useState<ActiveChatContext['activeChatId']>();
  const [activeMessageCursor, setActiveMessageCursor] =
    useState<ActiveChatContext['activeMessageCursor']>();
  const [isEditTagModalOpen, setIsEditTagModalOpen] = useState<boolean>(false);
  const [isOpenChat, setIsOpenChat] = useState<boolean>(false);
  const [isForwardMessageModalOpen, setIsForwardMessageModalOpen] =
    useState<boolean>(false);
  const [isDeleteMessageModalOpen, setIsDeleteMessageModalOpen] =
    useState<boolean>(false);
  const [cursorScrollRequested, setCursorScrollRequested] = useState(false);
  const [drawerCollapsed, setDrawerCollapsed] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [displayMessageList, setDisplayMessageList] = useState<
    ChatMessageListItem[]
  >([]);

  useEffect(() => {
    if (Platform.OS === 'web') {
      const infoStr = window.sessionStorage.getItem('screenInfo-activeChat');
      if (infoStr) {
        const info = JSON.parse(infoStr);
        setActiveChatId(info.activeChatId);
        setIsOpenChat(info.isOpenChat);
        setDrawerCollapsed(info.drawerCollapsed);
      }
    }
  }, []);

  useEffect(() => {
    // https://tasktag.atlassian.net/browse/TA-4012
    // 5. When the page is reloaded, everything is reset: the sidebar opens from a collapsed state, the task closes, the chat opens the main screen
    if (Platform.OS === 'web') {
      const info = {
        isOpenChat,
        activeChatId: activeChat?.id,
        drawerCollapsed,
      };
      window.sessionStorage.setItem(
        'screenInfo-activeChat',
        JSON.stringify(info)
      );
    }
  }, [activeChat, drawerCollapsed]);

  return (
    <Provider
      value={{
        activeChatId,
        activeChat,
        setActiveChat,
        isOpenChat,
        setIsOpenChat,
        activeMessageCursor,
        setActiveMessageCursor,
        cursorScrollRequested,
        setCursorScrollRequested,
        isEditTagModalOpen,
        setIsEditTagModalOpen,
        isForwardMessageModalOpen,
        setIsForwardMessageModalOpen,
        isDeleteMessageModalOpen,
        setIsDeleteMessageModalOpen,
        drawerCollapsed,
        setDrawerCollapsed,
        displayMessageList,
        setDisplayMessageList,
        isScrolling,
        setIsScrolling,
      }}>
      {children}
    </Provider>
  );
};

const useActiveChat = (): ActiveChatContext => {
  const context = useContext(activeChatContext);
  if (context === undefined) {
    throw new Error('useActiveChat must be used within a Provider');
  }
  return context;
};

export default useActiveChat;

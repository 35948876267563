import { Platform } from 'react-native';

import { Message, useDeleteMessageMutation } from '@graphql/generated';

export const useDeleteMessage = () => {
  const [deleteMessage] = useDeleteMessageMutation({
    refetchQueries:
      Platform.OS === 'web' ? ['listMessages', 'listChats'] : ['listMessages'],
  });

  const deleteMessageById = (message: Message, onComplete?: () => void) => {
    message?.id &&
      deleteMessage({
        variables: { id: message?.id },
        onCompleted: onComplete,
        update(cache) {
          const cacheIdentifier = cache.identify(message);
          cache.evict({ id: cacheIdentifier });
          cache.gc();
        },
      });
  };

  return { deleteMessageById };
};

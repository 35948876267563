/* eslint-disable no-nested-ternary */
/* eslint-disable no-magic-numbers */
import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  Platform,
  Dimensions,
} from 'react-native';
import uuid from 'react-native-uuid';

import Avatar, { AvatarProps } from '@components/Avatar/Avatar';
import { Box, Text } from '@components/Restyle/index';

export interface MemberListSummaryProps {
  avatars: AvatarProps[];
  onPress?: () => void;
  disabled?: boolean;
  maxAvatars?: number;
  isReadBy?: boolean;
  isSmall?: boolean;
}

const MemberListSummary = ({
  avatars,
  onPress,
  disabled,
  maxAvatars = 3,
  isReadBy = false,
  isSmall = false,
}: MemberListSummaryProps) => {
  const counter = avatars.length - maxAvatars;
  const { width } = Dimensions.get('window');

  const counterBox = () => {
    if (counter <= 0) return;
    return (
      <Box
        style={[
          width < 1025
            ? styles.responsiveAvatarContainer
            : isSmall
            ? styles.smallAvatarContainer
            : styles.avatarContainer,
          isReadBy && styles.readByAvatarContainer,
        ]}>
        <Text variant='metadata' color='white'>
          +{counter.toString()}
        </Text>
      </Box>
    );
  };

  return (
    <TouchableOpacity onPress={onPress} disabled={disabled}>
      <Box flexDirection='row'>
        {avatars
          .filter((_, index) => index < maxAvatars)
          .map((avatar, index) => {
            return (
              <Box
                key={avatar.id || avatar.avatar?.id || uuid.v4().toString()}
                style={
                  index !== 0 && [
                    width < 1025
                      ? styles.responsiveAvatarContainer
                      : isSmall
                      ? styles.smallAvatarContainer
                      : styles.avatarContainer,
                    isReadBy && styles.readByAvatarContainer,
                  ]
                }>
                <Avatar {...avatar} isSmall={isSmall} />
              </Box>
            );
          })}
        {avatars.length > maxAvatars && counterBox()}
      </Box>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  avatarContainer: {
    marginLeft: -14,
    width: Platform.OS == 'web' ? 32 : 24,
    height: Platform.OS == 'web' ? 32 : 24,
    borderRadius: Platform.OS == 'web' ? 16 : 12,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    borderWidth: 1,
    borderColor: 'white',
  },
  smallAvatarContainer: {
    marginLeft: -14,
    width: 24,
    height: 24,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
  },
  responsiveAvatarContainer: {
    marginLeft: -10,
    width: 24,
    height: 24,
    borderRadius: 13,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    borderWidth: 1,
    borderColor: 'white',
  },
  readByAvatarContainer: {
    marginLeft: -9,
    width: 34,
    height: 34,
    borderRadius: 17,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    borderWidth: 1,
    borderColor: 'white',
  },
});

export default MemberListSummary;

const ALLOWED_EXTENSIONS = [
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.bmp',
  '.tiff',
  '.doc',
  '.pdf',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.txt',
  '.rtf',
  '.odt',
  '.mp3',
  '.wav',
  '.aac',
  '.ogg',
  '.mp4',
  '.avi',
  '.mov',
  '.wmv',
  '.zip',
  '.tar',
  '.7z',
];

const DANGEROUS_EXTENSIONS = [
  '.php',
  '.php5',
  '.pht',
  '.phtml',
  '.shtml',
  '.asa',
  '.cer',
  '.asax',
  '.swf',
  '.xap',
  '.asp',
  '.aspx',
  '.jsp',
  '.exe',
  '.svg',
];

export const isValidExtension = (filename: string): boolean => {
  const lowerCaseFilename = filename.toLowerCase();
  const extension = lowerCaseFilename.slice(lowerCaseFilename.lastIndexOf('.'));
  if (!ALLOWED_EXTENSIONS.includes(extension)) {
    return false;
  }

  const parts = lowerCaseFilename.split('.');
  if (parts.length > 2) {
    parts.forEach((item) => {
      if (DANGEROUS_EXTENSIONS.includes(item)) return false;
    });
  }

  const dangerousPatterns: RegExp[] = [
    /;\w+/, // e.g. file.asp;.jpg
    /\/\w+/, // e.g. file.php/filename
    /\\\w+/, // e.g. file.php\filename
    /\.\w+::$Index_Allocation/, // e.g. folder.asp::$Index_Allocation
    /\.\w+:$/, // e.g. file.asax:.
    /%00/, // e.g. null character
    /\.$/, // e.g. trailing dot
    /\\$/, // e.g. trailing backslash
  ];

  for (const pattern of dangerousPatterns) {
    if (pattern.test(lowerCaseFilename)) {
      return false;
    }
  }

  return true;
};

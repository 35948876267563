import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomTabBar from '@components/CustomTabBar/CustomTabBar';
import { ConfirmModal } from '@components/Modals/ConfirmModal';
import RadioButton from '@components/RadioButton/RadioButton';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { MenuItem } from '@components/shared/Popup/PopupItem';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { PopupIcon } from '@components/Web/PopupIcon';
import {
  ListTasksDocument,
  Project,
  ProjectMemberRole,
  Task,
  TaskMemberRole,
  TaskStatus,
  TeamMemberRole,
  useDeleteTaskMutation,
  User,
} from '@graphql/generated';
import useTask from '@hooks/useTask';
import { useTaskDetailMenuActions } from '@hooks/useTaskDetailMenuActions';
import { useTaskMembership } from '@hooks/useTaskMembership';
import { useToggleCompleteTask } from '@hooks/useToggleCompleteTask';
import { useAppNavigation } from '@navigation/useAppNavigation';
import { TaskCheckList } from '@screens/Tasks/TaskCheckList.web';
import { TaskDetail } from '@screens/Tasks/TaskDetail.web';
import TaskFiles from '@screens/Tasks/TaskFiles.web';
import { TaskMembers } from '@screens/Tasks/TaskMembers.web';
import theme from '@themes/theme';

export type ProjectDetailTabsParamList = {
  'task-details': undefined;
  'activity-log': { taskId: Task['id'] };
  members: { taskId: Task['id']; isTaskAssignee?: boolean };
  files: { taskId: Task['id'] };
  checklist: { taskId: Task['id'] };
};

const Tabs = createMaterialTopTabNavigator<ProjectDetailTabsParamList>();

export const TaskDetailTabs = ({
  isMaximize,
  isFromMyTask,
  task,
  onClose,
  onMaximize,
  externalNavigate,
  onPressCreator,
  onPressMember,
  onPressProject,
}: {
  isMaximize: boolean;
  isFromMyTask: boolean;
  task: Task;
  onClose: () => void;
  onMaximize: () => void;

  externalNavigate?: any;
  onPressCreator?: (task: Task) => void;
  onPressMember?: (item: User) => void;
  onPressProject?: (project: Project) => void;
}) => {
  const navigation = externalNavigate ?? useAppNavigation();

  const { t } = useTranslation('models');

  const { completedAt, status, currentUserProjectRole } = task;

  const navItems = useTaskDetailMenuActions(task)?.filter(
    (i): i is MenuItem => 'name' in i
  );

  const { isMemberOrOwner } = useTaskMembership(task);
  const isEditorOrHigher = currentUserProjectRole !== ProjectMemberRole.Viewer;

  const usersCount = task.members.length;

  const completed = status === TaskStatus.Completed || !!completedAt;
  const [showCompleteTaskModal, setShowCompleteTaskModal] = useState<boolean>();

  const { currentUserRole, currentUserProjectTeamRole } = task;
  const canComplete =
    currentUserRole === TaskMemberRole.Assignee ||
    currentUserRole === TaskMemberRole.Owner ||
    currentUserProjectRole === ProjectMemberRole.Owner ||
    currentUserProjectRole === ProjectMemberRole.Admin ||
    currentUserProjectRole === ProjectMemberRole.Editor ||
    currentUserProjectTeamRole === TeamMemberRole.Owner ||
    currentUserProjectTeamRole === TeamMemberRole.Admin;
  const isTaskAssignee = currentUserRole === TaskMemberRole.Assignee;
  const isProjectTeamOwnerOrAdmin =
    currentUserProjectTeamRole === TeamMemberRole.Owner ||
    currentUserProjectTeamRole === TeamMemberRole.Admin;
  const { toggleCompleteTask } = useToggleCompleteTask();
  const { showDeleteTaskModal, setShowDeleteTaskModal } = useTask();
  const { setIsTaskWebPanelOpen } = useWebDrawer();

  const [deleteTask] = useDeleteTaskMutation({
    onCompleted: () => {
      setShowDeleteTaskModal(false);
      setIsTaskWebPanelOpen(false);
    },
    refetchQueries: [
      { query: ListTasksDocument, variables: { id: task.projectId } },
      'listTasks',
    ],
  });

  return (
    <>
      <Tabs.Navigator
        screenOptions={{ swipeEnabled: false }}
        tabBar={(props) => (
          <Box pb='s' backgroundColor='white'>
            <Box flexDirection='row' justifyContent='space-between'>
              <Box flex={1} alignItems='flex-end'>
                <Box flexDirection='row' alignItems='center'>
                  {(isMemberOrOwner ||
                    isEditorOrHigher ||
                    isProjectTeamOwnerOrAdmin) && (
                    <PopupIcon
                      accessibilityLabel='Options'
                      name='MoreVertical'
                      color='textPrimary'
                      popupProps={{
                        position: 'left',
                        offset: [0, theme.spacing.m],
                        menuList: navItems,
                      }}
                    />
                  )}
                  <Icon
                    accessibilityLabel='Expand task'
                    paddingHorizontal='xs'
                    onPress={onMaximize}
                    name={isMaximize ? 'Minimize2' : 'Maximize2'}
                    color='textPrimary'
                  />
                  <Icon accessibilityLabel='Close' name='X' onPress={onClose} />
                </Box>
              </Box>
            </Box>
            <Box mb='s' flexDirection='row' alignItems='center'>
              <Box
                accessibilityLabel='Complete task'
                justifyContent='center'
                marginRight='xs'>
                <RadioButton
                  onPress={() =>
                    completed
                      ? toggleCompleteTask(task)
                      : setShowCompleteTaskModal(true)
                  }
                  isSelected={completed}
                  disabled={!canComplete}
                  iconVariant='m'
                />
              </Box>
              <Text accessibilityLabel='Task title' variant='heading2'>
                {task.name}
              </Text>
            </Box>
            <Box>
              <CustomTabBar {...props} />
            </Box>
          </Box>
        )}>
        <Tabs.Screen
          name='task-details'
          navigationKey={`task-details-${task.id}`}
          options={{ tabBarLabel: 'Task Details' }}>
          {() => (
            <TaskDetail
              isFromMyTask={isFromMyTask}
              showActivityLog={
                isMemberOrOwner || isEditorOrHigher || isProjectTeamOwnerOrAdmin
              }
              task={task as Task}
              onCreatorPress={() => onPressCreator && onPressCreator(task)}
              onEditDatePress={(fieldName: string) =>
                navigation.navigate('task-detail-edit-dates', {
                  taskId: task.id,
                  fieldName,
                })
              }
              onPressProject={() =>
                onPressProject && onPressProject(task.project)
              }
            />
          )}
        </Tabs.Screen>
        {(isMemberOrOwner || isEditorOrHigher || isProjectTeamOwnerOrAdmin) && (
          <>
            <Tabs.Screen
              name='checklist'
              options={{ tabBarLabel: 'Checklist' }}
              component={TaskCheckList}
              initialParams={{ taskId: task.id }}
              navigationKey={`task-checklists-${task.id}`}
            />
            <Tabs.Screen
              name='members'
              navigationKey={`task-members-${task.id}`}
              options={{
                tabBarLabel: t('tasks.membersWithCount', {
                  context: isMemberOrOwner || isEditorOrHigher ? '' : 'guest',
                  count: usersCount,
                }),
              }}>
              {() => (
                <TaskMembers
                  onPressMember={onPressMember}
                  taskId={task.id}
                  isTaskAssignee={isTaskAssignee}
                />
              )}
            </Tabs.Screen>
            <Tabs.Screen
              name='files'
              options={{ tabBarLabel: 'Files & Media' }}
              component={TaskFiles}
              initialParams={{ taskId: task.id }}
              navigationKey={`task-files-${task.id}`}
            />
          </>
        )}
      </Tabs.Navigator>
      {showDeleteTaskModal && (
        <ConfirmModal
          showModal={showDeleteTaskModal}
          onClose={() => setShowDeleteTaskModal(false)}
          onPress={() => {
            deleteTask({
              variables: {
                id: task.id,
              },
            });
          }}
          buttonText={t('models:deleteTask.title')}
          title={t('models:deleteTask.title')}
          message={t('models:deleteTask.message')}
        />
      )}
      {showCompleteTaskModal && (
        <ConfirmModal
          showModal={showCompleteTaskModal}
          onClose={() => setShowCompleteTaskModal(false)}
          onPress={() => {
            toggleCompleteTask(task);
            setShowCompleteTaskModal(false);
          }}
          buttonText={t('models:tasks.complete.title')}
          title={t('models:tasks.complete.title')}
          message={t('models:tasks.complete.message_short')}
        />
      )}
    </>
  );
};

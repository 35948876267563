import React, { useEffect, useRef, useState } from 'react';
import { Platform, TouchableOpacity, useWindowDimensions } from 'react-native';
// use gesture handler to solve android absolute position flatlist bug
import { FlatList } from 'react-native-gesture-handler';
import Highlighter from 'react-native-highlight-words';

import Avatar from '@components/Avatar/Avatar';
import { Box, Text } from '@components/Restyle';
import { User } from '@graphql/generated';
import useMe from '@hooks/useMe';
import theme from '@themes/theme';

export type EveryoneType = {
  id: string;
  name: string;
};

const EVERYONE_LABEL = '@Everyone';
const EVERYONE_STRING = 'Everyone';
export const EVERYONE_ID = 'everyone';

const DEBOUNCE_VALUE = 10;

type SuggestionsProvidedProps = {
  keyword?: string;
  isGroupChat?: boolean;
  chatInputHeight: number;
  keyboardShow: boolean;
  onSuggestionPress?: (item: User | EveryoneType) => void;
  onSelect?: (item: User | EveryoneType) => void;
  chatUsers: User[];
  isShowUsers?: boolean;
  setIsShowUsers?: React.Dispatch<boolean>;
  hitEnterOrTabToSelectUser?: boolean;
  setHitEnterOrTabToSelectUser?: React.Dispatch<boolean>;
  currentUserIndex?: number;
  setCurrentUserIndex?: React.Dispatch<number>;
  setFilteredChatUsers?: React.Dispatch<React.SetStateAction<User[]>>;
};

const MentionSuggestions: React.FC<SuggestionsProvidedProps> = ({
  keyword,
  isGroupChat,
  chatInputHeight,
  keyboardShow,
  onSuggestionPress,
  onSelect,
  chatUsers,
  isShowUsers,
  setIsShowUsers,
  hitEnterOrTabToSelectUser,
  setHitEnterOrTabToSelectUser,
  currentUserIndex,
  setCurrentUserIndex,
  setFilteredChatUsers,
}) => {
  const { height } = useWindowDimensions();
  const { me } = useMe();
  const [chatUserData, setChatUsers] = useState<User[]>(chatUsers);
  const ref = useRef<FlatList<User>>(null);

  // throttle keyword to prevent suggestion box  flashing
  const [debouncedKeyword, setDebouncedKeyword] = useState(keyword);
  useEffect(() => {
    if (Platform.OS === 'web' && isGroupChat) {
      const mentionUserData = Object.assign([
        { id: EVERYONE_ID, name: EVERYONE_STRING },
        ...chatUsers,
      ]);
      setChatUsers(mentionUserData);
    } else {
      setChatUsers(chatUsers);
    }
  }, [chatUsers]);

  useEffect(() => {
    const timeout = setTimeout(
      () => setDebouncedKeyword(keyword),
      DEBOUNCE_VALUE
    );
    return () => clearTimeout(timeout);
  }, [keyword]);

  const filteredUsers = chatUserData.filter((user) =>
    user?.name
      ?.toLocaleLowerCase()
      .includes(debouncedKeyword?.toLocaleLowerCase())
  );

  useEffect(
    () => setFilteredChatUsers && setFilteredChatUsers(filteredUsers),
    [filteredUsers]
  );

  useEffect(() => {
    Platform.OS === 'web' &&
      !isShowUsers &&
      setIsShowUsers &&
      setIsShowUsers(true);
    Platform.OS === 'web' &&
      currentUserIndex !== 0 &&
      setCurrentUserIndex &&
      setCurrentUserIndex(0);
  }, []);

  useEffect(() => {
    if (Platform.OS === 'web' && hitEnterOrTabToSelectUser && isShowUsers) {
      currentUserIndex !== undefined &&
        filteredUsers[currentUserIndex] &&
        onSuggestionPress &&
        onSuggestionPress(filteredUsers[currentUserIndex]);
      setHitEnterOrTabToSelectUser && setHitEnterOrTabToSelectUser(false);
      setIsShowUsers && setIsShowUsers(false);
    }
  }, [hitEnterOrTabToSelectUser]);

  useEffect(() => {
    Platform.OS === 'web' &&
      currentUserIndex != undefined &&
      currentUserIndex >= 0 &&
      currentUserIndex < filteredUsers.length &&
      ref.current?.scrollToIndex({ animated: true, index: currentUserIndex });
  }, [currentUserIndex]);

  if (debouncedKeyword === undefined) return null;

  const displayEveryoneLabel =
    (EVERYONE_LABEL.toLowerCase().startsWith(
      debouncedKeyword.toLowerCase(),
      1
    ) ||
      !debouncedKeyword) &&
    isGroupChat;

  if (!filteredUsers.length && !displayEveryoneLabel) return null;

  // leading space - do not suggest
  if (debouncedKeyword.trimStart().length !== debouncedKeyword.length)
    return null;

  const setFlatListHeight = (isKeyboardShow: boolean) => {
    if (Platform.OS === 'web') {
      return '30vh';
    } else {
      if (isKeyboardShow) {
        if (height < 700) {
          if (chatInputHeight < 220) {
            return 140;
          } else if (chatInputHeight > 220) {
            return 80;
          }
        } else {
          return 140;
        }
      } else {
        return 316;
      }
    }
  };

  return (
    <FlatList
      ref={ref}
      showsVerticalScrollIndicator={false}
      onScrollToIndexFailed={() => {
        ref.current?.scrollToIndex({ animated: true, index: 0 });
      }}
      style={{
        backgroundColor: 'white',
        marginBottom:
          Platform.OS === 'web' ? theme.spacing.l : -theme.spacing.s,
        borderRadius: theme.borderRadii.m,
        marginHorizontal: theme.spacing.s,
        maxHeight: setFlatListHeight(keyboardShow),
        shadowColor: 'black',
        shadowOpacity: Platform.OS === 'android' ? 0.5 : 0.25,
        shadowOffset: { width: 0, height: Platform.OS === 'web' ? 5 : 25 },
        shadowRadius: 20,
        elevation: Platform.OS === 'web' ? 0 : 25,
        borderColor: theme.colors.grey02,
        borderWidth: 1,
      }}
      keyboardShouldPersistTaps='always'
      data={filteredUsers}
      renderItem={({ item: user, index }) => (
        <Box
          paddingTop={Platform.OS === 'web' ? 's' : undefined}
          paddingBottom={Platform.OS === 'web' ? 'xs' : undefined}
          backgroundColor={
            Platform.OS === 'web' && currentUserIndex === index
              ? 'grey01'
              : undefined
          }>
          <TouchableOpacity
            key={user.id}
            onPress={() =>
              Platform.OS === 'web' ? onSuggestionPress(user) : onSelect(user)
            }>
            <Box
              alignItems='center'
              alignSelf='flex-start'
              flexDirection='row'
              paddingHorizontal='m'>
              <Avatar avatar={user?.avatar} size='small' label={user.name} />
              {user?.name && (
                <Text variant='labelSmall' numberOfLines={1} marginLeft='xs'>
                  <Highlighter
                    autoEscape
                    highlightStyle={{
                      color: theme.colors.blue,
                      fontFamily: 'Inter_600SemiBold',
                      backgroundColor: theme.colors.background,
                    }}
                    searchWords={[debouncedKeyword]}
                    textToHighlight={
                      user.name + (me?.id === user.id ? ' (you)' : '')
                    }
                  />
                </Text>
              )}
            </Box>
          </TouchableOpacity>
        </Box>
      )}
      ItemSeparatorComponent={() => (
        <Box
          borderColor='grey01'
          borderBottomWidth={1}
          paddingTop={Platform.OS === 'web' ? undefined : 'xs'}
          marginBottom={Platform.OS === 'web' ? undefined : 's'}
          marginHorizontal='m'
        />
      )}
      ListHeaderComponent={() => {
        if (Platform.OS !== 'web' && displayEveryoneLabel) {
          return (
            <TouchableOpacity
              onPress={() =>
                Platform.OS === 'web'
                  ? onSuggestionPress({
                      id: EVERYONE_ID,
                      name: EVERYONE_STRING,
                    })
                  : onSelect({ id: EVERYONE_ID, name: EVERYONE_STRING })
              }>
              <Box
                marginTop='m'
                marginBottom='s'
                borderColor='grey01'
                borderBottomWidth={1}
                paddingBottom='xs'
                marginHorizontal='m'>
                <Text variant='labelSmall'>
                  <Highlighter
                    autoEscape
                    highlightStyle={{
                      color: theme.colors.greenSecondary,
                      backgroundColor: theme.colors.background,
                    }}
                    searchWords={[debouncedKeyword]}
                    textToHighlight={EVERYONE_LABEL}
                  />
                </Text>
              </Box>
            </TouchableOpacity>
          );
        } else {
          return <Box paddingTop='xs' />;
        }
      }}
      ListFooterComponent={() => <Box marginBottom='s' />}
    />
  );
};

export default MentionSuggestions;

import * as Clipboard from 'expo-clipboard';
import { openURL } from 'expo-linking';
import linkifyIt from 'linkify-it';
import { Platform } from 'react-native';
import Hyperlink from 'react-native-hyperlink';
import tlds from 'tlds';

import { Alert } from '@components/Alert/index.web';
import { useAlert } from '@hooks/useAlert.web';
import theme from '@themes/theme';

const linkify = linkifyIt();
const linkifyConfig = linkify.tlds(tlds);

interface HyperLinkProps {
  children?: JSX.Element;
}

/** Provides access to an object that exposes the hover state of the component */
export const HyperLink: React.FC<HyperLinkProps> = ({ children }) => (
  <Hyperlink
    linkify={linkifyConfig}
    onLongPress={(url) => {
      Clipboard.setStringAsync(url).then(() => {
        const copiedConfirm = `${url} copied to clipboard`;
        useAlert().showAlert(copiedConfirm);
      });
    }}
    onPress={(url) => {
      if (Platform.OS == 'web') {
        window.open(url, '_blank');
      } else {
        openURL(url).catch(() => Alert.alert(`invalid URL: ${url}`));
      }
    }}
    linkStyle={{
      color: theme.colors.blue,
      alignSelf: 'flex-start',
    }}>
    {children}
  </Hyperlink>
);

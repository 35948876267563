import React from 'react';
import Highlighter from 'react-native-highlight-words';

import { Box, Text } from '@components/Restyle';
import theme from '@themes/theme';

interface SearchTextProps {
  searchWords: string[];
  textToHighlight: string;
}

export const ChatSearchText: React.FC<SearchTextProps> = ({
  searchWords,
  textToHighlight,
}) => {
  return (
    <Box alignItems='center' style={{ marginTop: 6, height: 24 }}>
      <Text variant='metadata' color='grey04' numberOfLines={1}>
        <Highlighter
          highlightStyle={{
            fontFamily: 'Inter_600SemiBold',
            backgroundColor: 'white',
            color: theme.colors.grey04,
          }}
          searchWords={searchWords}
          autoEscape
          textToHighlight={textToHighlight}
        />
      </Text>
    </Box>
  );
};
